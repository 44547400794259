import { emptySplitApi } from "../emptySplit-api";

export const commonApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    commonDropdown: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: `/common/dropdowns`,
          method: "post",
          body: data,
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const { useCommonDropdownQuery } = commonApi;
