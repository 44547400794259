import { createContext, useContext, useState } from "react";

export const DropdownContext: any = createContext(null);

export const DropdownProvider = ({ children }: any) => {
  const [dropdownData, setDropdownData] = useState({});

  return (
    <DropdownContext.Provider value={{ dropdownData, setDropdownData }}>
      {children}
    </DropdownContext.Provider>
  );
};

export const useDropdowns = () => {
  return useContext(DropdownContext);
};
