const CustomSwitch = (row: any, handleSwitch: any) => {
  return (
    <div
      className="form-check form-switch"
      onClick={() => handleSwitch(row.original._id, row.original.isActive)}
    >
      <input
        className="form-check-input"
        type="checkbox"
        id={row.original._id}
        checked={row.original.isActive}
      />
      <label className="form-check-label" htmlFor={row.original._id}></label>
    </div>
  );
};

export default CustomSwitch;
