import { LABELS, MESSAGE } from "../../../../../shared/constants";

const CustomItemsOrder = (props: any) => {
  const { customItems } = props;

  return (
    <>
      <span className="d-block text-center mb-3">
        {MESSAGE.CUSTOM_ORDER_ITEM_MESSAGE}
      </span>
      <table className="table table-striped mb-2 table-bordered">
        <thead>
          <tr>
            <th>{LABELS.LIST_OF_CUSTOM_ITEM_TEXT}</th>
          </tr>
        </thead>
        <tbody>
          {customItems.map((el: any) => {
            return (
              <tr>
                <td>{el}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default CustomItemsOrder;
