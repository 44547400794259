import { API_URL } from "../../shared/constants";
import { emptySplitApi } from "../emptySplit-api";

export const inventoryApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    inventoryList: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.INVENTORIES}/list`,
          method: "post",
          body: data,
        };
      },
    }),
    inventoryGet: builder.query({
      query: (id) => ({
        url: `/${API_URL.INVENTORIES}/get/${id}`,
        method: "post",
      }),
    }),
    inventorySearch: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.INVENTORIES}/search`,
          method: "post",
          body: data,
        };
      },
    }),
    inventoryDesign: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.INVENTORIES}/design`,
          method: "post",
          body: data,
        };
      },
    }),
    inventoryFabricCode: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.INVENTORIES}/fabric-code`,
          method: "post",
          body: data,
        };
      },
    }),
    inventorySizeColor: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.INVENTORIES}/size-color`,
          method: "post",
          body: data,
        };
      },
    }),
    inventorySize: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.INVENTORIES}/size`,
          method: "post",
          body: data,
        };
      },
    }),
    inventoryColor: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.INVENTORIES}/color`,
          method: "post",
          body: data,
        };
      },
    }),
    inventoryImport: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.INVENTORIES}/${data.get("url")}`,
          method: "post",
          body: data,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useInventoryListQuery,
  useInventoryGetQuery,
  useInventorySearchQuery,
  useInventoryDesignQuery,
  useInventoryFabricCodeQuery,
  useInventorySizeColorQuery,
  useInventorySizeQuery,
  useInventoryColorQuery,
  useInventoryImportMutation,
} = inventoryApi;
