import { useEffect, useMemo, useRef, useState } from "react";
import Navbar from "../../Navbar/NavbarComponent";
import CustomModal from "../../../shared/components/CustomModal";
import AddUser from "./AddUser";
import UserDetail from "./UserDetail";
import {
  useUsersListQuery,
  useUsersGetQuery,
  useUsersCreateMutation,
  useUsersUpdateMutation,
  useUsersDeleteMutation,
  useUsersImportMutation,
  useUsersExportMutation,
} from "../../../Features/user/user-slice";
import {
  ACTION_LABEL,
  BUTTON_LABEL,
  icons,
  LABELS,
  SEARCH_DATA,
  toastError,
  toastSuccess,
  MESSAGE,
} from "../../../shared/constants";
import Search from "../../../shared/components/Search";
import Table from "../../../shared/components/Table";
import { skipToken } from "@reduxjs/toolkit/query";
import { useDeleteModal } from "../../../shared/utils/hooks/useDeleteModal";
import { listCheck } from "../../../shared/helperFunc/listCheck";
import CustomSwitch from "../../../shared/components/CustomSwitch";
import { userValidation } from "../../../shared/validation/user-validation ";
import authProvider from "../../../shared/config/authProvider";
import SimpleButton from "../../../shared/components/Button/Button";
import { useFilter } from "../../../shared/utils/hooks/useFilter";
import Filter from "../../Common/Filter/Filter";
import { convertToAppropriateType } from "../../../shared/helperFunc/convertToAppropriateType";
import useDebounce from "../../../shared/utils/hooks/useDebounce";
import { DEBOUNCE_VALUE } from "../../../shared/constants/resource";

const User = () => {
  const downloadPath = process.env.REACT_APP_BACKEND_URL + "/uploads/";
  const [showUserModal, setShowUserModal] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [sort, setSort] = useState(SEARCH_DATA);
  const [search, setSearch] = useState(false);
  const [count, setCount] = useState();
  const [multiDelete, setMultiDelete] = useState(false);
  const [userList, setUserList] = useState([]);
  const [userId, setUserId]: any = useState(null);
  const [userDetailData, setUserDetailData] = useState();
  const [addUserData, setAddUserData]: any = useState({
    name: "",
    email: "",
    phone: "",
    designation: "",
    address: "",
    state: "",
    city: "",
    password: "",
    role: "",
    isActive: true,
  });
  const [editMode, setEditMode] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const iconInput = useRef<any>(null);
  const [handleFilter] = useFilter(sort, setSort);
  const [showFilter, setShowFilter] = useState(false);
  const [filterCalled, setFilterCalled]: any = useState(false);
  const [filterSelectionError, setFilterSelectionError]: any = useState();
  const [filterValues, setFilterValues] = useState<any>({});
  const [defaultFilterValues, setDefaultFilterValues] = useState<any>({});
  const [searchKeyword, setSearchKeyword] = useState(sort.search);
  const debouncedSearchKeyword = useDebounce(searchKeyword, DEBOUNCE_VALUE);

  const [
    showModal,
    deletedName,
    deleteId,
    deleteShowModal,
    deleteCloseModal,
    deleteShowModals,
  ] = useDeleteModal();

  const {
    data,
    isSuccess,
    refetch: userRefetch,
    isFetching: userListFetching,
  } = useUsersListQuery(
    debouncedSearchKeyword
      ? { ...sort, search: debouncedSearchKeyword, skip: 0 }
      : sort,
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const [userCreate, { isLoading: userCreateLoading }] =
    useUsersCreateMutation();

  const [userUpdate, { isLoading: userUpdateLoading }] =
    useUsersUpdateMutation();

  const [userDelete, { isLoading: userDeleteLoading }] =
    useUsersDeleteMutation();

  const [userImport] = useUsersImportMutation();

  const [userExport] = useUsersExportMutation();

  const {
    data: getUserData,
    isLoading: getUserLoading,
    isSuccess: getUserSuccess,
  } = useUsersGetQuery(userId || skipToken, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (isSuccess) {
      setUserList(data.list);
      setCount(data.count);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (userId && getUserSuccess) {
      setUserDetailData({
        ...getUserData.item,
        role: getUserData?.role?.name,
      });
    }
  }, [userId, getUserData, getUserSuccess]);

  const handleApplyFilter = () => {
    // if (Object.keys(sort.filter).length > 0) {
    handleFilter();
    setFilterCalled(!filterCalled);
    setSort({ ...sort, filter: filterValues, filterFlag: true, skip: 0 });
    setShowFilter(!showFilter);
    // } else {
    //   setFilterSelectionError("*Please select atlease one filter.");
    // }
  };

  const handleCallFilter = () => {
    setShowFilter(!showFilter);
  };

  const resetFilter = () => {
    setFilterValues(defaultFilterValues);
    setSort({ ...sort, filter: defaultFilterValues, filterFlag: false });
    setFilterCalled(!filterCalled);
    setFilterSelectionError("");
    setShowFilter(!showFilter);
  };

  const closeFilter = () => {
    setFilterValues(defaultFilterValues);
    setFilterCalled(!filterCalled);
    setFilterSelectionError("");
    setShowFilter(false);
  };

  const closeUserModal = () => {
    setShowUserModal(false);
    setUserId(null);
    setAddUserData({});
    setEditMode(false);
    setResetPassword(false);
    setErrors({});
  };

  const handleOnChange = (e: any) => {
    setAddUserData({ ...addUserData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleOnBlur = (e: any) => {
    const trimmedValue = e.target.value.trim();

    setAddUserData({
      ...addUserData,
      [e.target.name]: trimmedValue,
    });
  };

  const closeDetail = () => {
    setUserId(null);
    setShowDetail(false);
  };

  const handleUserModal = () => {
    setShowUserModal(true);
  };

  const handleSearch = () => {
    setSearch(!search);
    setSort({ ...sort, skip: 0 });
  };

  const handleSearchTextChange = (e: any) => {
    setSearchKeyword(e.target.value);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const userAddFlow = async (data: any) => {
    toastSuccess(data.message);
    setUserId(null);
    setEditMode(false);
    setErrors({});
    setShowUserModal(false);
    setAddUserData({});
    userRefetch();
  };

  const userUpdateFlow = async (data: any) => {
    toastSuccess(data.message);
    setUserId(null);
    setEditMode(false);
    setErrors({});
    setShowUserModal(false);
    setAddUserData({});
    userRefetch();
  };

  const userDeleteFlow = async (data: any) => {
    toastSuccess(data.message);
    deleteCloseModal();
    deleteShowModal([]);
    removeMultiDelete();
    setShowUserModal(false);
    userRefetch();
  };

  const handleSave = async () => {
    const { formErrors, formValid } = userValidation(
      addUserData,
      editMode ? resetPassword : true
    );
    if (formValid) {
      if (editMode) {
        const userUpdateResponse = await userUpdate({
          id: userId,
          data: addUserData,
        })
          .unwrap()
          .catch((err: any) => {
            toastError(err.data?.errors[0]?.msg);
          });
        if (userUpdateResponse && userUpdateResponse?.status) {
          userUpdateFlow(userUpdateResponse);
        }
      } else {
        const userAddResponse = await userCreate(addUserData)
          .unwrap()
          .catch((err: any) => {
            toastError(err.data?.errors[0]?.msg);
          });
        if (userAddResponse && userAddResponse?.status) {
          userAddFlow(userAddResponse);
        }
      }
    } else {
      setErrors(formErrors);
    }
  };

  const handleSwitchChange = async (id: any, isActive: any) => {
    const userUpdateResponse = await userUpdate({
      id: id,
      data: { isActive: !isActive },
    })
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (userUpdateResponse && userUpdateResponse?.status) {
      userUpdateFlow(userUpdateResponse);
    }
  };

  const addUserModalButton = [
    {
      text: BUTTON_LABEL.SAVE,
      action: handleSave,
      className: "btn theme-btn",
      isLoading: editMode ? userUpdateLoading : userCreateLoading,
    },
    {
      text: BUTTON_LABEL.CANCEL,
      action: closeUserModal,
      className: "btn btn theme-btn",
    },
  ];

  const userDetailModalButton = [
    {
      text: BUTTON_LABEL.CANCEL,
      action: closeDetail,
      className: "btn btn theme-btn btn-inverse",
    },
  ];

  const handleBlur = (e: any) => {
    const trimmedValue = e.target.value.trim();

    setFilterValues({
      ...filterValues,
      [e.target.name]: trimmedValue,
    });
  };

  const handleTextChange = (e: any) => {
    setFilterValues({
      ...filterValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect = (item: any, e: any, type: string) => {
    let selectedValue = convertToAppropriateType(item?.value, type);

    if (item?.value) {
      setFilterValues({
        ...filterValues,
        [e.target.name]: selectedValue,
      });
    } else {
      let updatedFilter: any = { ...filterValues };
      delete updatedFilter[e.name];
      setFilterValues(updatedFilter);
    }
    setFilterSelectionError("");
  };

  const handleDate = (dates: any, filterName: any) => {
    const [start, end] = dates;

    const fromDate = start ? new Date(start).toISOString() : null;
    let toDate = null;
    if (end) {
      var datestring = new Date(end).toISOString().split("T")[0];
      toDate = new Date(datestring + "T23:59:59.000+05:30").toISOString();
    }

    setFilterValues({
      ...filterValues,
      [filterName]: { from: fromDate, to: toDate },
    });
  };

  const filterContent = (
    handleTextChange: any,
    handleSelect: any,
    handleDate: any
  ) => {
    return [
      {
        label: LABELS.ROLE_TEXT,
        name: ACTION_LABEL.ROLE,
        elementType: "select",
        action: handleSelect,
        type: "",
        operator: "is from",
      },
      {
        label: LABELS.STATE_TEXT,
        name: ACTION_LABEL.STATE,
        elementType: "text",
        action: handleTextChange,
        type: "",
        operator: "is from",
      },
      {
        label: LABELS.CITY_TEXT,
        name: ACTION_LABEL.CITY,
        elementType: "text",
        action: handleTextChange,
        type: "",
        operator: "is from",
      },
      {
        label: LABELS.CREATED_AT_TEXT,
        name: ACTION_LABEL.CREATED_AT,
        elementType: "createdAt",
        action: handleDate,
        type: "",
        operator: "is from",
      },
      {
        label: LABELS.STATUS_TEXT,
        name: ACTION_LABEL.ISACTIVE,
        elementType: "select",
        action: handleSelect,
        type: "boolean",
        operator: "is from",
      },
    ];
  };

  const columns = useMemo(
    () => [
      {
        header: LABELS.FULLNAME_TEXT,
        accessorKey: ACTION_LABEL.NAME,
        isShow: true,
      },
      {
        header: LABELS.EMAIL_TEXT,
        accessorKey: ACTION_LABEL.EMAIL,
        isShow: true,
      },
      {
        header: LABELS.ROLE_TEXT,
        accessorKey: `${ACTION_LABEL.ROLE}.${ACTION_LABEL.NAME}`,
        isShow: true,
      },
      {
        header: LABELS.STATUS_TEXT,
        cell: ({ row }: any) => CustomSwitch(row, handleSwitchChange),
        isShow: true,
      },
    ],
    [sort]
  );

  const setCheckBox = (flag: any) => {
    let updated = listCheck(JSON.parse(JSON.stringify(userList)), flag);
    return updated;
  };

  const checkSelectedData = (data: any) => {
    return data.filter((ele: any) => {
      return ele.isSelected;
    });
  };

  const checked = (e: any, index: any) => {
    setMultiDelete(true);
    let data = JSON.parse(JSON.stringify(userList));

    if (e.target.checked) {
      data[index].isSelected = true;
    } else {
      data[index].isSelected = false;
    }

    setUserList(data);

    const visibleName = checkSelectedData(data);
    deleteShowModal(visibleName);
  };

  const removeMultiDelete = () => {
    let unSelected = setCheckBox(false);
    setUserList(unSelected);
    const visibleName = checkSelectedData(unSelected);
    deleteShowModal(visibleName);
  };

  const actionMultiDelete = () => {
    deleteShowModals();
    const visibleName = checkSelectedData(userList);
    deleteShowModal(visibleName);
  };

  const allCheck = (e: any) => {
    setMultiDelete(true);
    let data = [];
    data = setCheckBox(e.target.checked);
    const visibleName = checkSelectedData(data);
    deleteShowModal(visibleName);

    setUserList(data);
  };

  const handleView = (row: any) => {
    setUserId(row._id);
    setShowDetail(true);
  };

  const handleEdit = (row: any) => {
    setUserId(row._id);
    setShowUserModal(true);
  };

  const handleShowModal = (row: any) => (
    deleteShowModals(true), deleteShowModal([row])
  );

  const tableAction = (
    handleView: any,
    handleEdit: any,
    handleShowModal: any
  ) => {
    return [
      {
        elementType: "button",
        text: BUTTON_LABEL.VIEW,
        className: `${`icon ${icons.ICON_VIEW}`} mx-1 `,
        icon: `icon ${icons.ICON_VIEW}`,
        action: handleView,
        access: authProvider.show("users", "view"),
      },
      {
        elementType: "button",
        text: BUTTON_LABEL.EDIT,
        className: `${`icon ${icons.ICON_EDIT}`} mx-1 `,
        action: handleEdit,
        access: authProvider.show("users", "edit"),
      },
      {
        elementType: "button",
        text: BUTTON_LABEL.DELETE,
        className: `${`icon ${icons.ICON_DELETE}`} mx-1 `,
        action: handleShowModal,
        access: authProvider.show("users", "edit"),
      },
    ];
  };

  const action = tableAction(handleView, handleEdit, handleShowModal);

  const deleteModal = (handleDelete: any, closeModal: any, isLoading: any) => {
    return [
      {
        text: BUTTON_LABEL.YES,
        action: handleDelete,
        className: "btn theme-btn theme-btn-sm w-auto",
        isLoading: userCreateLoading,
      },
      {
        text: BUTTON_LABEL.NO,
        action: closeModal,
        className: "btn theme-btn theme-btn-sm btn-inverse w-auto",
      },
    ];
  };

  const handleDelete = async () => {
    const userDeleteResponse = await userDelete({ _id: deleteId })
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (userDeleteResponse && userDeleteResponse?.status) {
      userDeleteFlow(userDeleteResponse);
    }
  };

  const closeModal = () => {
    deleteCloseModal();
    deleteShowModal([]);
    removeMultiDelete();
  };

  const deleteModalButton = deleteModal(
    handleDelete,
    closeModal,
    userDeleteLoading
  );

  const handleResetPassword = () => {
    setResetPassword(!resetPassword);
  };

  const userImportFlow = (data: any) => {
    userRefetch();
    toastSuccess(data.message);
  };

  const userExportFlow = (data: any) => {
    let a = document.createElement("a");
    a.href = downloadPath + data.item;
    a.download = data.file;
    a.click();
    toastSuccess(data.message);
  };

  const handleRef = () => {
    iconInput.current.click();
  };

  const handleCsvImport = async (e: any) => {
    let formData = new FormData();
    formData.append("csv", e.target.files[0], e.target.files[0].name);
    const userImportResponse = await userImport(formData)
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (userImportResponse && userImportResponse?.status) {
      userImportFlow(userImportResponse);
    }
  };

  const handleCsvExport = async () => {
    const userExportResponse = await userExport("")
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (userExportResponse && userExportResponse?.status) {
      userExportFlow(userExportResponse);
    }
  };

  return (
    <>
      <div className="full-height">
        <Navbar title={LABELS.USER_MANAGEMENT_TEXT} />
        <main className="main-content">
          <div className="pt-3">
            <div className="container-fluid">
              <div className="row table-top-actions">
                <div className="col-12 col-md-12 d-flex justify-content-end">
                  <div className="d-flex align-items-center row w-100 mx-m-0">
                    <Search
                      placeholder={LABELS.SEARCH_TEXT}
                      name={ACTION_LABEL.SEARCH}
                      value={sort.search}
                      onChange={handleSearchTextChange}
                      onKeyDown={handleKeyDown}
                      onClick={handleSearch}
                    />

                    {authProvider.show("user", "edit") && (
                      <>
                        {/* <SimpleButton
                      datatoggle="tooltip"
                      dataplacement="top"
                      title={BUTTON_LABEL.IMPORT}
                      className={
                        "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                      }
                      onClick={handleRef}
                      text={BUTTON_LABEL.IMPORT}
                    >
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={iconInput}
                        onChange={handleCsvImport}
                        accept=".csv"
                        onClick={(e: any) => {
                          e.target.value = null;
                        }}
                        hidden
                      />
                    </SimpleButton> */}

                        <SimpleButton
                          className={
                            "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                          }
                          onClick={handleUserModal}
                          btnIcon={icons.ICON_ADD}
                          text={BUTTON_LABEL.ADD}
                        />
                      </>
                    )}

                    <SimpleButton
                      className={`me-2 btn theme-btn theme-btn-sm ${
                        Object.keys(sort.filter).length > 0 ? "" : "btn-inverse"
                      } filter-btn`}
                      btnIcon={icons.ICON_FILTER}
                      btnIconClass={"me-2 d-inline-block"}
                      onClick={handleCallFilter}
                    />
                  </div>
                </div>
              </div>
              <div className="card custom-card card-full mt-3">
                <div className="card-body p-0">
                  <Table
                    data={userList}
                    columns={columns}
                    select={true}
                    action={action}
                    sort={sort}
                    setSort={setSort}
                    allCheck={allCheck}
                    allCheckedCheckbox={deleteId.length === userList.length}
                    checked={checked}
                    coloumnDrop={false}
                    count={count}
                    isFetching={userListFetching}
                    deleteId={deleteId}
                    removeMultiDelete={removeMultiDelete}
                    actionMultiDelete={actionMultiDelete}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <CustomModal
        show={showModal}
        close={closeModal}
        message={`${MESSAGE.DELETE_MESSAGE} ${deletedName} User?`}
        size="md"
        modalTitle={LABELS.DELETE_USER_TEXT}
        modalButton={deleteModalButton}
        children={undefined}
        fullscreen={undefined}
        className={""}
        centerClass={""}
        modalTitleIcon={""}
        // modalTitleIcon={icons.ICON_DELETE_V2}
      />

      <CustomModal
        show={showUserModal}
        close={closeUserModal}
        size={"lg"}
        message={""}
        modalTitle={editMode ? LABELS.EDIT_USER_TEXT : LABELS.ADD_USER_TEXT}
        modalButton={addUserModalButton}
        fullscreen={undefined}
        className={""}
        centerClass={""}
        modalTitleIcon={""}
      >
        <AddUser
          userId={userId}
          editMode={editMode}
          setEditMode={setEditMode}
          addUserData={addUserData}
          setAddUserData={setAddUserData}
          resetPassword={resetPassword}
          setResetPassword={setResetPassword}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleResetPassword={handleResetPassword}
          errors={errors}
        />
      </CustomModal>

      <CustomModal
        show={showDetail}
        close={closeDetail}
        size={"lg"}
        message={""}
        modalTitle={LABELS.USER_MANAGEMENT_DETAILS_TEXT}
        modalButton={userDetailModalButton}
        fullscreen={undefined}
        className={"light-modal inventory-detail-modal"}
        centerClass={""}
        modalTitleIcon={""}
      >
        <UserDetail
          userDetailData={userDetailData}
          getUserLoading={getUserLoading}
        />
      </CustomModal>

      <Filter
        sort={filterValues}
        filterSelectionError={filterSelectionError}
        filterObject={filterContent(handleTextChange, handleSelect, handleDate)}
        show={showFilter}
        onClose={closeFilter}
        handleReset={resetFilter}
        handleApply={handleApplyFilter}
        handleOnBlur={handleBlur}
      />
    </>
  );
};

export default User;
