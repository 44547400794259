import { useEffect, useMemo, useRef, useState } from "react";
import CustomModal from "../../../shared/components/CustomModal";
import ReportDetail from "./ReportDetail";
import {
  ACTION_LABEL,
  BUTTON_LABEL,
  icons,
  LABELS,
  SEARCH_DATA,
  toastError,
  toastSuccess,
} from "../../../shared/constants";
import Navbar from "../../Navbar/NavbarComponent";
import Table from "../../../shared/components/Table";
import {
  useReportFollowUpQuery,
  useReportImportMutation,
  useReportListQuery,
} from "../../../Features/report/report-slice";
import { skipToken } from "@reduxjs/toolkit/query";
import SimpleButton from "../../../shared/components/Button/Button";
import { secondDateConvert } from "../../../shared/helperFunc/dateConvert";
import Filter from "../../Common/Filter/Filter";
import { useFilter } from "../../../shared/utils/hooks/useFilter";
import moment from "moment";
import { CSVLink } from "react-csv";
import useDebounce from "../../../shared/utils/hooks/useDebounce";
import { DEBOUNCE_VALUE } from "../../../shared/constants/resource";

const ReportList = (props: any) => {
  const {} = props;

  const [sort, setSort]: any = useState(SEARCH_DATA);
  const [reportFollowUpList, setReportFollowUpList] = useState([]);
  const [showReportModal, setShowReportModal] = useState(false);
  const [soNo, setsoNo]: any = useState();
  const [reportDetailData, setReportDetailData]: any = useState();
  const [count, setCount] = useState();
  const iconInput = useRef<any>(null);
  const [handleFilter] = useFilter(sort, setSort);
  const [showFilter, setShowFilter] = useState(false);
  const [filterCalled, setFilterCalled]: any = useState(false);
  const [filterSelectionError, setFilterSelectionError]: any = useState();
  const [filterValues, setFilterValues] = useState<any>({});
  const [searchKeyword, setSearchKeyword] = useState(sort.search);
  const debouncedSearchKeyword = useDebounce(searchKeyword, DEBOUNCE_VALUE);
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);
  const formattedToday = today.toISOString();
  const formattedSevenDaysAgo = sevenDaysAgo.toISOString();
  const [defaultFilterValues, setDefaultFilterValues] = useState<any>({});

  const {
    data,
    isSuccess,
    refetch: reportRefetch,
    isFetching: reportListFetching,
  } = useReportListQuery(
    debouncedSearchKeyword
      ? {
          ...sort,
          search: debouncedSearchKeyword,
          skip: 0,
          sortBy: ACTION_LABEL.STORE_DELIVERY_DATE,
        }
      : { ...sort, sortBy: ACTION_LABEL.STORE_DELIVERY_DATE },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: getReportData,
    isLoading: getReportLoading,
    isSuccess: getReportSuccess,
  } = useReportFollowUpQuery(soNo ? { soNo: soNo } : skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const [reportImport] = useReportImportMutation();

  useEffect(() => {
    if (isSuccess) {
      setReportFollowUpList(data.list);
      setCount(data.count);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (soNo && getReportSuccess) {
      setReportDetailData(getReportData?.item);
    }
  }, [soNo, getReportData, getReportSuccess]);

  useEffect(() => {
    setSort({
      ...SEARCH_DATA,
      filter: {
        deliveryStDate: {
          from: formattedSevenDaysAgo,
          to: formattedToday,
        },
      },
    });

    setFilterValues({
      deliveryStDate: {
        from: formattedSevenDaysAgo,
        to: formattedToday,
      },
    });
    setDefaultFilterValues({
      deliveryStDate: {
        from: formattedSevenDaysAgo,
        to: formattedToday,
      },
    });
  }, []);

  const reportImportFlow = (data: any) => {
    reportRefetch();
    toastSuccess(data.message);
  };

  const handleApplyFilter = () => {
    // if (Object.keys(sort.filter).length > 0) {
    handleFilter();
    setFilterCalled(!filterCalled);
    setSort({ ...sort, filter: filterValues, filterFlag: true, skip: 0 });
    setShowFilter(!showFilter);
    // } else {
    //   setFilterSelectionError("*Please select atlease one filter.");
    // }
  };

  const handleCallFilter = () => {
    setShowFilter(!showFilter);
  };

  const resetFilter = () => {
    setFilterValues(defaultFilterValues);
    setSort({ ...sort, filter: defaultFilterValues, filterFlag: false });
    setFilterCalled(!filterCalled);
    setFilterSelectionError("");
    setShowFilter(!showFilter);
  };

  const closeFilter = () => {
    setFilterValues(defaultFilterValues);
    setFilterCalled(!filterCalled);
    setFilterSelectionError("");
    setShowFilter(false);
  };

  const closeReportModal = () => {
    setShowReportModal(false);
    setReportDetailData();
    setsoNo();
  };

  const handleReportDetails = (e: any, row: any) => {
    setsoNo(row.soNo);
    setShowReportModal(true);
  };

  const handleRef = () => {
    iconInput.current.click();
  };

  const handleCsvImport = async (e: any) => {
    let formData = new FormData();
    formData.append("csv", e.target.files[0], e.target.files[0].name);
    const reportImportResponse = await reportImport(formData)
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (reportImportResponse && reportImportResponse?.status) {
      reportImportFlow(reportImportResponse);
    }
  };

  const reportDetailModalButton = [
    {
      text: BUTTON_LABEL.CANCEL,
      action: closeReportModal,
      className: "btn btn theme-btn",
    },
  ];

  const handleOnBlur = (e: any) => {
    const trimmedValue = e.target.value.trim();

    setFilterValues({
      ...filterValues,
      [e.target.name]: trimmedValue,
    });
  };

  const handleDate = (dates: any, filterName: any) => {
    const [start, end] = dates;
    const fromDate = start ? new Date(start).toISOString() : null;
    let toDate = null;
    if (end) {
      var datestring = new Date(end).toISOString().split("T")[0];
      toDate = new Date(datestring + "T23:59:59.000+05:30").toISOString();
    }
    setFilterValues({
      ...filterValues,
      [filterName]: { from: fromDate, to: toDate },
    });
  };

  const csvData = [
    {
      SO_NO: "249474",
      SO_DATE: "02-12-2024",
      CUSTOMER: "GINNI RETAIL-ESPLANDE",
      ORDER_TYPE: "MTM",
      DELIVERY_ST_DATE: "03-01-2025",
      DIV: "FINISH GOODS",
      DEPARTMENT: "SHIRT",
      BARCODE: "HM103",
      DOCUMENT_NO: "MTM-2025-565",
      DESIGN: "HM101-786",
      COLOUR: "Blue",
      SIZE: "42",
      SUB_ASSEMBLY_ITEM: "Shirt",
      PROCESS: "Cutting",
      JOB_ORDER_NO: "JOB/00061647/24-25",
      JOB_ORDER_DATE: "05-12-2024",
      JOB_RECEIPT_DATE: "05-12-2024",
      JOB_ORDER_QTY: "1",
      JOB_COMPLETED_QTY: "0",
      JOB_CNL_QTY: "0",
      JOB_PENDING_QTY: "1",
    },
    {
      SO_NO: "249475",
      SO_DATE: "05-12-2024",
      CUSTOMER: "GINNI RETAIL-ESPLANDE",
      ORDER_TYPE: "MTM",
      DELIVERY_ST_DATE: "05-01-2025",
      DIV: "FINISH GOODS",
      DEPARTMENT: "KURTA",
      BARCODE: "HM101",
      DOCUMENT_NO: "MTM-2025-566",
      DESIGN: "HM102-786",
      COLOUR: "Yellow",
      SIZE: "44",
      SUB_ASSEMBLY_ITEM: "Kurta",
      PROCESS: "Embroidery",
      JOB_ORDER_NO: "JOB/00061648/24-25",
      JOB_ORDER_DATE: "09-12-2024",
      JOB_RECEIPT_DATE: "09-12-2024",
      JOB_ORDER_QTY: "1",
      JOB_COMPLETED_QTY: "0",
      JOB_CNL_QTY: "1",
      JOB_PENDING_QTY: "1",
    },
    {
      SO_NO: "249476",
      SO_DATE: "08-12-2024",
      CUSTOMER: "Optculture Store",
      ORDER_TYPE: "MTM",
      DELIVERY_ST_DATE: "10-01-2025",
      DIV: "FINISH GOODS",
      DEPARTMENT: "PANT",
      BARCODE: "HM102",
      DOCUMENT_NO: "MTM-2025-564",
      DESIGN: "HM103-786",
      COLOUR: "Blue",
      SIZE: "36",
      SUB_ASSEMBLY_ITEM: "Pant",
      PROCESS: "Cutting",
      JOB_ORDER_NO: "JOB/00061649/24-25",
      JOB_ORDER_DATE: "15-12-2024",
      JOB_RECEIPT_DATE: "15-12-2024",
      JOB_ORDER_QTY: "1",
      JOB_COMPLETED_QTY: "1",
      JOB_CNL_QTY: "0",
      JOB_PENDING_QTY: "0",
    },
    {
      SO_NO: "249478",
      SO_DATE: "04-01-2025",
      CUSTOMER: "Optculture Store",
      ORDER_TYPE: "MTM",
      DELIVERY_ST_DATE: "02-02-2025",
      DIV: "FINISH GOODS",
      DEPARTMENT: "SHIRT",
      BARCODE: "HM105",
      DOCUMENT_NO: "MTM-2025-562",
      DESIGN: "HM105-786",
      COLOUR: "Red",
      SIZE: "40",
      SUB_ASSEMBLY_ITEM: "Shirt",
      PROCESS: "Stitching",
      JOB_ORDER_NO: "JOB/00061650/24-25",
      JOB_ORDER_DATE: "14-01-2025",
      JOB_RECEIPT_DATE: "14-01-2025",
      JOB_ORDER_QTY: "1",
      JOB_COMPLETED_QTY: "0",
      JOB_CNL_QTY: "0",
      JOB_PENDING_QTY: "1",
    },
  ];

  const filterContent = (handleDate: any) => {
    return [
      {
        label: LABELS.STORE_DELIVERY_DATE_TEXT,
        name: ACTION_LABEL.STORE_DELIVERY_DATE,
        elementType: "createdAt",
        action: handleDate,
        type: "",
        operator: "is from",
      },
    ];
  };

  const columns = useMemo(
    () => [
      {
        header: LABELS.SO_NO_TEXT,
        accessorKey: ACTION_LABEL.SO_NO,
        isShow: true,
      },
      {
        header: LABELS.CUSTOMER_NAME_TEXT,
        accessorKey: ACTION_LABEL.CUSTOMER,
        isShow: true,
      },
      {
        header: LABELS.DOCUMENT_NO_TEXT,
        accessorKey: ACTION_LABEL.DOCUMENT_NO,
        isShow: true,
      },
      {
        header: LABELS.STATUS_TEXT,
        cell: (row: any) => {
          return row.row.original.status ? (
            <div className="status text-success">{LABELS.CONFIRM_TEXT}</div>
          ) : (
            <div className="status text-danger">{LABELS.PENDING_TEXT}</div>
          );
        },
        isShow: true,
      },
      {
        header: LABELS.DELIVERY_DATE_TEXT,
        accessorKey: ACTION_LABEL.STORE_DELIVERY_DATE,
        cell: ({ row }: any) =>
          secondDateConvert(row.original[ACTION_LABEL.STORE_DELIVERY_DATE]),
        isShow: true,
      },
      {
        header: LABELS.REMAINING_DAY_TEXT,
        cell: (row: any) => {
          const deliveryStDate = row?.row?.original?.deliveryStDate;

          const formattedDeliveryDate = moment(deliveryStDate).startOf("day");

          const currentDate = moment().startOf("day");

          const diffInDays = formattedDeliveryDate.diff(currentDate, "days");

          return diffInDays;
        },
        isShow: true,
      },
    ],
    []
  );

  return (
    <>
      <div className="full-height">
        <Navbar title={LABELS.FOLLOW_UP_TEXT} />
        <main className="main-content">
          <div className="pt-3">
            <div className="container-fluid">
              <div className="row table-top-actions">
                <div className="col-12 col-md-12 d-flex justify-content-end">
                  <div className="d-flex align-items-center row w-100 mx-m-0">
                    <SimpleButton
                      datatoggle="tooltip"
                      dataplacement="top"
                      title={BUTTON_LABEL.IMPORT}
                      className={
                        "me-2 ms-auto btn theme-btn theme-btn-sm btn-inverse w-auto"
                      }
                      onClick={handleRef}
                      text={BUTTON_LABEL.IMPORT}
                    >
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={iconInput}
                        onChange={handleCsvImport}
                        accept=".csv"
                        onClick={(e: any) => {
                          e.target.value = null;
                        }}
                        hidden
                      />
                    </SimpleButton>
                    <CSVLink
                      data={csvData}
                      filename={"FollowUpReport.csv"}
                      className="w-auto px-0"
                    >
                      <SimpleButton
                        datatoggle="tooltip"
                        dataplacement="top"
                        title={BUTTON_LABEL.SAMPLE_CSV}
                        className={
                          "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                        }
                        text={BUTTON_LABEL.SAMPLE_CSV}
                      />
                    </CSVLink>
                    <SimpleButton
                      className={`me-2 btn theme-btn theme-btn-sm ${
                        Object.keys(sort.filter).length > 0 ? "" : "btn-inverse"
                      } filter-btn`}
                      btnIcon={icons.ICON_FILTER}
                      btnIconClass={"me-2 d-inline-block"}
                      onClick={handleCallFilter}
                    />
                  </div>
                </div>
              </div>
              <div className="card custom-card card-full mt-3">
                <div className="card-body p-0">
                  <Table
                    data={reportFollowUpList}
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    coloumnDrop={true}
                    count={count}
                    isFetching={reportListFetching}
                    handleRow={handleReportDetails}
                    isHandleRowEnabled
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <CustomModal
        show={showReportModal}
        close={closeReportModal}
        size={"lg"}
        message={""}
        modalTitle={
          reportDetailData &&
          `${reportDetailData?.customer} | ${reportDetailData?.soNo}`
        }
        modalButton={reportDetailModalButton}
        fullscreen={undefined}
        className={"light-modal inventory-detail-modal report-modal"}
        centerClass={""}
        modalTitleIcon={""}
      >
        <ReportDetail
          reportDetailData={reportDetailData}
          getReportLoading={getReportLoading}
        />
      </CustomModal>

      <Filter
        sort={filterValues}
        filterSelectionError={filterSelectionError}
        filterObject={filterContent(handleDate)}
        show={showFilter}
        onClose={closeFilter}
        handleReset={resetFilter}
        handleApply={handleApplyFilter}
        handleOnBlur={handleOnBlur}
      />
    </>
  );
};

export default ReportList;
