import { createContext, useEffect, useState } from "react";
const AuthContext = createContext<any>(null);
const { Provider } = AuthContext;

const AuthProvider = ({ children }: any) => {
  const userData = localStorage.getItem("userInfo");
  const [authState, setAuthState] = useState({
    userInfo: userData ? JSON.parse(userData) : null,
  });

  const setAuthInfo = ({ userInfo }: any) => {
    setAuthState({
      userInfo,
    });
  };

  const checkTokenValidity = () => {
    const userData = localStorage.getItem("userInfo");
    const parsedData = userData ? JSON.parse(userData) : null;

    if (!parsedData || !parsedData.token) {
      setAuthState({ userInfo: null });
      localStorage.removeItem("userInfo");
    } else {
      setAuthState({ userInfo: parsedData });
    }
  };

  useEffect(() => {
    checkTokenValidity();

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "userInfo") {
        checkTokenValidity();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <Provider
      value={{
        authState,
        setAuthState: (authInfo: any) => setAuthInfo(authInfo),
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
