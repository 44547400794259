import TextBox from "../../../../shared/components/TextBox";
import TextArea from "../../../../shared/components/TextArea";
import Password from "../../../../shared/components/Password";
import { useStoreSearchQuery } from "../../../../Features/store/store-slice";
import Select from "../../../../shared/components/Select";
import { useEffect } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import CheckBox from "../../../../shared/components/CheckBox/CheckBox";
import { ACTION_LABEL, LABELS } from "../../../../shared/constants";
import { useStoreCoordinatorGetQuery } from "../../../../Features/user/store-coordinators-slice";
import Loader from "../../../../shared/components/Loader";
import { USER_TYPE_DROP } from "../../../../shared/constants/resource";

const AddStoreCoordinator = (props: any) => {
  const {
    storeCoordinatorId,
    editMode,
    setEditMode,
    addStoreCoordinatorData,
    setAddStoreCoordinatorData,
    resetPassword,
    handleOnChange,
    handleOnBlur,
    handleResetPassword,
    errors,
  } = props;

  const { data: storeSearchData, isFetching: storeFetching } =
    useStoreSearchQuery("");

  const {
    data: getStoreCoordinatorData,
    isLoading: getStoreCoordinatorLoading,
    isSuccess: getStoreCoordinatorSuccess,
  } = useStoreCoordinatorGetQuery(storeCoordinatorId || skipToken, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (storeCoordinatorId && getStoreCoordinatorSuccess) {
      setEditMode(true);
      setAddStoreCoordinatorData(getStoreCoordinatorData.item);
    }
  }, [
    storeCoordinatorId,
    getStoreCoordinatorData,
    getStoreCoordinatorSuccess,
    setAddStoreCoordinatorData,
  ]);

  return (
    <>
      {!getStoreCoordinatorLoading ? (
        <>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={ACTION_LABEL.NAME}
                  placeholder={LABELS.FULLNAME_TEXT}
                  id={LABELS.FULLNAME_TEXT}
                  label={LABELS.FULLNAME_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addStoreCoordinatorData.name}
                  errorMessage={errors.name || null}
                  required
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={ACTION_LABEL.USERNAME}
                  placeholder={LABELS.USERCODE_TEXT}
                  id={LABELS.USERCODE_TEXT}
                  label={LABELS.USERCODE_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addStoreCoordinatorData.username}
                  errorMessage={errors.username || null}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={ACTION_LABEL.EMAIL}
                  placeholder={LABELS.EMAIL_TEXT}
                  id={LABELS.EMAIL_TEXT}
                  label={LABELS.EMAIL_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addStoreCoordinatorData.email}
                  errorMessage={errors.email || null}
                  required
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={ACTION_LABEL.PHONE}
                  placeholder={LABELS.PHONE_TEXT}
                  id={LABELS.PHONE_TEXT}
                  label={LABELS.PHONE_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addStoreCoordinatorData.phone}
                  errorMessage={errors.phone || null}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={ACTION_LABEL.DESIGNATION}
                  placeholder={LABELS.DESIGNATION_TEXT}
                  id={LABELS.DESIGNATION_TEXT}
                  label={LABELS.DESIGNATION_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addStoreCoordinatorData.designation}
                  errorMessage={errors.designation || null}
                  required
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <Select
                  data={USER_TYPE_DROP}
                  label={LABELS.USER_TYPE_TEXT}
                  id={LABELS.USER_TYPE_TEXT}
                  name={ACTION_LABEL.TYPE}
                  value={addStoreCoordinatorData.type}
                  onChange={handleOnChange}
                  errorMessage={errors.type || null}
                  required
                  disable={editMode && "disable"}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <Select
                  data={
                    !storeFetching && storeSearchData
                      ? storeSearchData.list
                      : []
                  }
                  label={LABELS.STORE_TEXT}
                  id={LABELS.STORE_TEXT}
                  name={ACTION_LABEL.STORE}
                  value={addStoreCoordinatorData.store}
                  onChange={handleOnChange}
                  errorMessage={errors.store || null}
                  required
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={ACTION_LABEL.STATE}
                  placeholder={LABELS.STATE_TEXT}
                  id={LABELS.STATE_TEXT}
                  label={LABELS.STATE_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addStoreCoordinatorData.state}
                  errorMessage={errors.state || null}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={ACTION_LABEL.CITY}
                  placeholder={LABELS.CITY_TEXT}
                  id={LABELS.CITY_TEXT}
                  label={LABELS.CITY_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addStoreCoordinatorData.city}
                  errorMessage={errors.city || null}
                  required
                />
              </div>
            </div>

            {editMode ? (
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    htmlFor={LABELS.RESET_PASSWORD_TEXT}
                    className="d-block mb-2"
                  >
                    &nbsp;
                  </label>
                  <CheckBox
                    label={LABELS.RESET_PASSWORD_TEXT}
                    name={ACTION_LABEL.RESET_PASSWORD}
                    id={LABELS.RESET_PASSWORD_TEXT}
                    value={resetPassword}
                    onChange={handleResetPassword}
                  />
                </div>
              </div>
            ) : (
              <div className="col-6">
                <div className="form-group">
                  <Password
                    placeholder={LABELS.PASSWORD_TEXT}
                    label={LABELS.PASSWORD_TEXT}
                    value={addStoreCoordinatorData.password}
                    name={ACTION_LABEL.PASSWORD}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    errorMessage={errors.password || null}
                    required
                  />
                </div>
              </div>
            )}

            {resetPassword ? (
              <div className="col-6">
                <div className="form-group">
                  <Password
                    placeholder={LABELS.PASSWORD_TEXT}
                    label={LABELS.PASSWORD_TEXT}
                    value={addStoreCoordinatorData.password}
                    name={ACTION_LABEL.PASSWORD}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    errorMessage={errors.password || null}
                    required
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <TextArea
                  name={ACTION_LABEL.ADDRESS}
                  placeholder={LABELS.ADDRESS_TEXT}
                  id={LABELS.ADDRESS_TEXT}
                  label={LABELS.ADDRESS_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addStoreCoordinatorData.address}
                  errorMessage={errors.address || null}
                  required
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AddStoreCoordinator;
