import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Logo } from "../App/assets/img";
import { loginValidation } from "../shared/validation/login-validation";
import {
  ACTION_LABEL,
  BUTTON_LABEL,
  icons,
  INPUT_TYPES,
  LABELS,
  MESSAGE,
  toastError,
  toastSuccess,
  URLS,
} from "../shared/constants";
import { useLoginMutation } from "../Features/auth/auth-api-slice";
import { AuthContext } from "../context/AuthContext";
import TextBox from "../shared/components/TextBox";
import PasswordBox from "../shared/components/Password/PasswordBox";

const Login = () => {
  const [login] = useLoginMutation();
  const authContext = useContext(AuthContext);

  const [loginDetail, setLoginDetail] = useState({
    username: "",
    password: "",
  });

  const [error, setError] = useState<any>({});

  const handleChange = (e: any) => {
    setLoginDetail({
      ...loginDetail,
      [e.target.name]: e.target.value,
    });
    setError({ ...error, [e.target.name]: "" });
  };

  const handleOnBlur = (e: any) => {
    const trimmedValue = e.target.value.trim();

    setLoginDetail({
      ...loginDetail,
      [e.target.name]: trimmedValue,
    });
  };

  const loginFlow = async (loginData: any) => {
    await authContext.setAuthState({
      userInfo: loginData,
    });
    localStorage.setItem("userInfo", JSON.stringify(loginData));
    localStorage.setItem("x-access-token", loginData.token);
    toastSuccess(loginData.message);
  };

  const handleLogin = async (e: any) => {
    e.preventDefault();
    const { formErrors, formValid } = loginValidation(loginDetail);

    if (formValid) {
      const LoginResponse = await login({
        username: loginDetail.username,
        password: loginDetail.password,
      })
        .unwrap()
        .catch((err: any) => {
          toastError(err?.data?.errors[0]?.msg);
        });
      if (LoginResponse && LoginResponse?.status) {
        loginFlow(LoginResponse);
      }
    } else {
      setError(formErrors);
    }
  };

  return (
    <>
      <div className="login-wrapper">
        <div className="row align-middle">
          <div className="col-md-4 mx-auto">
            <div className="brand-logo text-center mb-3">
              <img src={Logo} alt="lock" width={350} className="img-fluid" />
            </div>

            <h2 className="h2 text-center mb-4">{MESSAGE.TITLE_TEXT}</h2>
            <form>
              <div className="form-group form-group-icon">
                <TextBox
                  name={ACTION_LABEL.USERNAME}
                  placeholder={LABELS.USERNAME_TEXT}
                  id={LABELS.USERNAME_TEXT}
                  label={LABELS.USERNAME_TEXT}
                  onChange={handleChange}
                  onBlur={handleOnBlur}
                  value={loginDetail.username}
                  icon={icons.ICON_MAIL}
                  errorMessage={error.username || null}
                />
              </div>
              <div className="form-group form-group-icon">
                <PasswordBox
                  name={ACTION_LABEL.PASSWORD}
                  placeholder={LABELS.PASSWORD_TEXT}
                  id={LABELS.PASSWORD_TEXT}
                  label={LABELS.PASSWORD_TEXT}
                  onChange={handleChange}
                  value={loginDetail.password}
                  icon={icons.ICON_KEY}
                  errorMessage={error.password || null}
                  required
                />
              </div>
              <div className="form-group my-3">
                <Link to={URLS.RESET_PASSWORD}>
                  {LABELS.FORGOT_PASSWORD_TEXT}
                </Link>
              </div>
              <div className="custom-checkbox">
                <input
                  className="custom-control-input"
                  type={INPUT_TYPES.CHECKBOX}
                  id="inlineCheckbox1"
                  value="option1"
                />
                <label
                  className="custom-control-label"
                  htmlFor="inlineCheckbox1"
                >
                  {LABELS.REMEMBER_ME_TEXT}
                </label>
              </div>
              <div className="text-center mt-3">
                <Button
                  variant="default"
                  className="btn theme-btn text-uppercase"
                  onClick={(e: any) => handleLogin(e)}
                  type="submit"
                >
                  {BUTTON_LABEL.LOGIN}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
