import { useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { Link, useLocation } from "react-router-dom";
import { sidebarData } from "../../../shared/constants/resource";
import { Logo } from "../../../App/assets/img";
import { Collapse } from "react-bootstrap";
import { BUTTON_LABEL, icons, URLS } from "../../../shared/constants";
import authProvider from "../../../shared/config/authProvider";
import SimpleButton from "../../../shared/components/Button/Button";

const Sidebar = () => {
  const authContext = useContext(AuthContext);
  const { authState } = authContext;
  const location = useLocation();
  const [openId, setOpenId] = useState<any>();
  const [selectedLink, setSelectedLink] = useState("");
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const handleCollaps = (id: any, link: any) => {
    if (id === openId) {
      setOpenId(null);
    } else {
      setSelectedLink(link);
      setOpenId(id);
    }
  };

  const handleToggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const usertype = authState?.userInfo?.item?.type;
  const userPermissions = authState?.userInfo?.permissions || [];

  const filteredSidebarData = sidebarData.filter((ele: any) => {
    const hasPermission = ele.permissions
      ? ele.permissions.some((perm: any) => userPermissions.includes(perm))
      : true;
    return (
      (ele.visibleFor ? ele.visibleFor.includes(usertype) : true) &&
      hasPermission
    );
  });

  return (
    <div className={`sidebar ${!isSidebarVisible ? "isShow" : ""}`}>
      <button onClick={handleToggleSidebar} className={"nav-link sidebar-btn"}>
        <div></div>
        <div></div>
        <div></div>
      </button>
      <Link className="navbar-brand" to={URLS.DASHBOARD}>
        <img src={Logo} alt="lock" width={350} />
      </Link>
      <nav className="navbar">
        <div className="navbar-nav">
          {filteredSidebarData.map((ele: any, index: any) => {
            return (
              authProvider.show(ele.permName, ele.type) && (
                <div key={index}>
                  <Link
                    to={ele.path}
                    onClick={() => handleCollaps(index, ele.path)}
                    className={`nav-link ${
                      selectedLink === ele.path && "sidebar-collapse"
                    }`}
                  >
                    <i className={`icon ${ele.icon}`} />
                    {ele.title}
                  </Link>

                  {openId === index && (
                    <Collapse in={openId}>
                      <div>
                        {ele.subItems
                          ?.filter((subitem: any) => {
                            const hasSubitemPermission = subitem.permissions
                              ? subitem.permissions.some((perm: any) =>
                                  userPermissions.includes(perm)
                                )
                              : true;
                            return hasSubitemPermission;
                          })
                          .map((subitem: any, subIndex: any) => {
                            return (
                              <Link
                                key={subIndex}
                                className={`nav-link ${
                                  location.pathname.includes(subitem.path) &&
                                  "active"
                                }`}
                                to={subitem.path}
                              >
                                {subitem.title}
                              </Link>
                            );
                          })}
                      </div>
                    </Collapse>
                  )}
                </div>
              )
            );
          })}
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
