import { useState } from "react";
import Loader from "../../../../shared/components/Loader";
import { icons } from "../../../../shared/constants";

const ReportDetail = (props: any) => {
  const { reportDetailData, getReportLoading } = props;

  const [detailIndex, setDetailIndex] = useState();

  const toggleShowSubAssemblyItem = (index: any) => {
    setDetailIndex(index);
  };

  const toggleHideSubAssemblyItem = () => {
    setDetailIndex(undefined);
  };

  const trackStatusBlock = (processItem: any) => {
    let iconClass = "";
    let statusClass = "";

    if (processItem.jobCompletedQty) {
      iconClass = icons.ICON_CHECK;
      statusClass = "done";
    } else if (processItem.jobCnlQty) {
      iconClass = icons.ICON_REFRESH;
      statusClass = "in-progress";
    } else if (processItem.jobPendingQty) {
      iconClass = "";
      statusClass = "";
      // } else {
      //   iconClass = icons.ICON_IN_PROGRESS;
      //   statusClass = "in-progress";
    }
    return (
      <li>
        <div className="d-flex w-max-content justify-content-center flex-wrap">
          <div className={`track-status ${statusClass}`}>
            <i className={iconClass}></i>
          </div>
          <div className="w-100 text-center">{processItem.process}</div>
        </div>
      </li>
    );
  };

  return (
    <>
      {!getReportLoading ? (
        <div className="report-items">
          {reportDetailData?.items?.map((el: any, index: any) => {
            return (
              <div className="tracking-status">
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="slug-custom-dropdown mt-4 d-inline-block w-100"
                      onClick={
                        detailIndex === index
                          ? () => toggleHideSubAssemblyItem()
                          : () => toggleShowSubAssemblyItem(index)
                      }
                    >
                      <div className="toggle d-flex align-items-center w-100 justify-content-between active-panel">
                        <span>{el.itemCode}</span>
                        <span className="ms-3">
                          <i
                            className={
                              detailIndex === index
                                ? icons.ICON_CHEVRON_UP
                                : icons.ICON_CHEVRON_DOWN
                            }
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {detailIndex === index && (
                  <>
                    {el?.subAssemblyItem?.map((subItem: any) => {
                      return (
                        <div
                          className={`row mt-3 align-items-center assembally-attr ${
                            index === detailIndex && "assembally-attr-visible"
                          }`}
                        >
                          <div className="col-md-3">
                            <div className="slug-custom-dropdown d-inline-block w-100">
                              <div className="toggle d-flex align-items-center w-100 justify-content-between">
                                <span>{subItem.name}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-9">
                            <div className="track-stepper">
                              <ul className="list-unstyled my-0">
                                {subItem?.process?.map((pItem: any) => {
                                  return trackStatusBlock(pItem);
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <Loader isAuto />
      )}
    </>
  );
};

export default ReportDetail;
