import { API_URL } from "../../shared/constants";
import { emptySplitApi } from "../emptySplit-api";

export const userApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    usersList: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.USERS}/list`,
          method: "post",
          body: data,
        };
      },
    }),
    usersCreate: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.USERS}/create`,
          method: "post",
          body: data,
        };
      },
    }),
    usersUpdate: builder.mutation<any, any>({
      query: ({ id, data }) => {
        return {
          url: `/${API_URL.USERS}/update/${id}`,
          method: "post",
          body: data,
        };
      },
    }),
    usersDelete: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.USERS}/delete`,
          method: "post",
          body: data,
        };
      },
    }),
    usersGet: builder.query<any, any>({
      query: (id: any) => {
        return {
          url: `/${API_URL.USERS}/get/${id}`,
          method: "post",
        };
      },
    }),
    usersImport: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.USERS}/import`,
          method: "post",
          body: data,
        };
      },
    }),
    usersExport: builder.mutation<any, any>({
      query: () => {
        return {
          url: `/${API_URL.USERS}/export`,
          method: "post",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useUsersListQuery,
  useUsersCreateMutation,
  useUsersUpdateMutation,
  useUsersDeleteMutation,
  useUsersGetQuery,
  useUsersImportMutation,
  useUsersExportMutation,
} = userApi;
