import { API_URL } from "../../shared/constants";
import { emptySplitApi } from "../emptySplit-api";

export const storeCoordinatorApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    storeCoordinatorList: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.STORE_COORDINATORS}/list`,
          method: "post",
          body: data,
        };
      },
    }),
    storeCoordinatorCreate: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.STORE_COORDINATORS}/create`,
          method: "post",
          body: data,
        };
      },
    }),
    storeCoordinatorUpdate: builder.mutation<any, any>({
      query: ({ id, data }) => {
        return {
          url: `/${API_URL.STORE_COORDINATORS}/update/${id}`,
          method: "post",
          body: data,
        };
      },
    }),
    storeCoordinatorDelete: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.STORE_COORDINATORS}/delete`,
          method: "post",
          body: data,
        };
      },
    }),
    storeCoordinatorGet: builder.query<any, any>({
      query: (id: any) => {
        return {
          url: `/${API_URL.STORE_COORDINATORS}/get/${id}`,
          method: "post",
        };
      },
    }),
    csvImport: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: "/import",
          method: "post",
          body: data,
        };
      },
    }),
    csvExport: builder.mutation<any, any>({
      query: () => {
        return {
          url: `/${API_URL.STORE_COORDINATORS}/export`,
          method: "post",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useStoreCoordinatorListQuery,
  useStoreCoordinatorCreateMutation,
  useStoreCoordinatorUpdateMutation,
  useStoreCoordinatorDeleteMutation,
  useStoreCoordinatorGetQuery,
  useCsvExportMutation,
  useCsvImportMutation,
} = storeCoordinatorApi;
