import TextArea from "../../../../../shared/components/TextArea";
import { ACTION_LABEL, LABELS } from "../../../../../shared/constants";

const CancelOrder = (props: any) => {
  const { orderCancelData, handleTextChange, handleBlur } = props;
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <TextArea
              name={ACTION_LABEL.REMARKS}
              placeholder={LABELS.REMARKS_TEXT}
              id={LABELS.REMARKS_TEXT}
              label={LABELS.REMARKS_TEXT}
              onChange={handleTextChange}
              onBlur={handleBlur}
              value={orderCancelData.remarks}
              maxLength={40}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CancelOrder;
