import { useEffect, useState } from "react";
import Navbar from "../../../Navbar/NavbarComponent/Navbar";
import {
  BUTTON_LABEL,
  LABELS,
  DATE_STANDARD_FORMAT,
  ACTION_LABEL,
  toastError,
  toastSuccess,
  URLS,
  icons,
  MESSAGE,
} from "../../../../shared/constants";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  useOrderConfirmMutation,
  useOrderGetQuery,
  useOrderUpdateMutation,
  useOrderCancelMutation,
} from "../../../../Features/order/order-slice";
import moment from "moment";
import SimpleButton from "../../../../shared/components/Button/Button";
import Loader from "../../../../shared/components/Loader";
import {
  useInventoryDesignQuery,
  useInventoryFabricCodeQuery,
  useInventorySearchQuery,
  useInventorySizeQuery,
  useInventoryColorQuery,
} from "../../../../Features/inventory/inventory-slice";
import TextBox from "../../../../shared/components/TextBox";
import authProvider from "../../../../shared/config/authProvider";
import ButtonLoader from "../../../../shared/components/ButtonLoader";
import {
  DEBOUNCE_VALUE,
  OrderStatus,
  ORDER_STATUS,
} from "../../../../shared/constants/resource";
import CustomModal from "../../../../shared/components/CustomModal";
import CancelOrder from "./CancelOrder";
import SelectionSearch from "../../../../shared/components/SelectionSearch";
import useDebounce from "../../../../shared/utils/hooks/useDebounce";
import { mergeUniqueArrays } from "../../../../shared/helperFunc/mergeUniqueArrays";
import { getCustomItems } from "../../../../shared/helperFunc/getCustomItems";
import CustomItemsOrder from "./CustomItemsOrder";

const OrderDetail = (props: any) => {
  const {} = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const [editOrderDetail, setEditOrderDetail] = useState(false);
  const [orderId, setOrderId]: any = useState(id);
  const [orderDetailData, setOrderDetailData]: any = useState();
  const [orderItemIndex, setOrderItemIndex]: any = useState();
  const [selectedData, setSelectedData]: any = useState();
  const [orderCancelData, setOrderCancelData]: any = useState({
    remarks: "",
  });
  const [inventorySearchList, setInventorySearchList]: any = useState();
  const [inventoryDesignList, setInventoryDesignList]: any = useState();
  const [inventoryFabricCodeList, setInventoryFabricCodeList]: any = useState();
  const [inventorySizeList, setInventorySizeList]: any = useState();
  const [inventoryColorList, setInventoryColorList]: any = useState();
  const [showCancelOrder, setShowCancelOrder] = useState(false);
  const [showConfirmOrder, setShowConfirmOrder] = useState(false);
  const [searchKeyword, setSearchKeyword]: any = useState("");
  const debouncedSearchKeyword = useDebounce(searchKeyword, DEBOUNCE_VALUE);
  const [isSearchActive, setIsSearchActive]: any = useState({
    itemCode: false,
    designNo: false,
    fabricCode: false,
    size: false,
    color: false,
  });
  const [selectedItemData, setSelectedItemData]: any = useState();
  const [selectedDesignData, setSelectedDesignData]: any = useState();
  const [selectedFabricData, setSelectedFabricData]: any = useState();
  const [selectedSizeData, setSelectedSizeData]: any = useState();
  const [selectedColorData, setSelectedColorData]: any = useState();
  const [showCustomItem, setShowCustomItem] = useState(false);
  const [customItems, setCustomItems]: any = useState();
  const [hasTyped, setHasTyped] = useState(false);
  // let d = {
  //   search: selectedData?.itemCode,
  //   color: selectedData?.color,
  //   fabricCode: selectedData?.fabricCode,
  //   design: selectedData?.design,
  //   size: selectedData?.size,
  // };

  const isItemApiCall = () => {
    if (!editOrderDetail || !hasTyped) return false;
    if (debouncedSearchKeyword !== undefined) return true;
    if (isSearchActive.itemCode) return true;
  };

  const isDesignApiCall = () => {
    if (!editOrderDetail || !hasTyped) return false;
    if (isSearchActive.designNo) return true;
  };

  const isFabricApiCall = () => {
    if (!editOrderDetail || !hasTyped) return false;
    if (isSearchActive.fabricCode) return true;
  };

  const isSizeApiCall = () => {
    if (!editOrderDetail || !hasTyped) return false;
    if (isSearchActive.size) return true;
  };

  const isColorApiCall = () => {
    if (!editOrderDetail || !hasTyped) return false;
    if (isSearchActive.color) return true;
  };

  const getItemCodeRequestPayload = () => {
    if (selectedData?.isCustom || isSearchActive.itemCode) {
      return { search: debouncedSearchKeyword };
    }
  };

  const getDesignRequestPayload = () => {
    if (selectedData?.isCustom || isSearchActive.designNo) {
      return { search: debouncedSearchKeyword };
    }
  };

  const getFabricRequestPayload = () => {
    if (selectedData?.isCustom && isSearchActive.fabricCode) {
      return { search: debouncedSearchKeyword || "" };
    } else {
      return {
        search: debouncedSearchKeyword || "",
        design: selectedData?.design || "",
      };
    }
  };

  const getSizeRequestPayload = () => {
    if (selectedData?.isCustom && isSearchActive.size) {
      return { search: debouncedSearchKeyword || "" };
    } else {
      return {
        search: debouncedSearchKeyword || "",
        design: selectedData?.design || "",
        fabricCode: selectedData?.fabricCode || "",
      };
    }
  };

  const getColorRequestPayload = () => {
    if (selectedData?.isCustom && isSearchActive.color) {
      return { search: debouncedSearchKeyword || "" };
    } else {
      return {
        search: debouncedSearchKeyword || "",
        design: selectedData?.design || "",
        fabricCode: selectedData?.fabricCode || "",
      };
    }
  };

  const {
    data: getOrderData,
    isLoading: getOrderLoading,
    isSuccess: getOrderSuccess,
    refetch: getOrderRefetch,
  } = useOrderGetQuery(orderId || skipToken, {
    refetchOnMountOrArgChange: true,
  });

  // const searchPayload = editOrderDetail ? d : skipToken;

  const {
    data: inventorySearchData,
    isSuccess: inventorySearchSuccess,
    isFetching: inventorySearchFetching,
  } = useInventorySearchQuery(
    // searchPayload,
    isItemApiCall() ? getItemCodeRequestPayload() : skipToken,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: inventoryDesignData,
    isSuccess: inventoryDesignSuccess,
    isFetching: inventoryDesignFetching,
  } = useInventoryDesignQuery(
    isDesignApiCall() ? getDesignRequestPayload() : skipToken,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: inventoryFabricCodeData,
    isSuccess: inventoryFabricCodeSuccess,
    isFetching: inventoryFabricCodeFetching,
  } = useInventoryFabricCodeQuery(
    isFabricApiCall() ? getFabricRequestPayload() : skipToken,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: inventorySizeData,
    isSuccess: inventorySizeSuccess,
    isFetching: inventorySizeFetching,
  } = useInventorySizeQuery(
    isSizeApiCall() ? getSizeRequestPayload() : skipToken,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: inventoryColorData,
    isSuccess: inventoryColorSuccess,
    isFetching: inventoryColorFetching,
  } = useInventoryColorQuery(
    isColorApiCall() ? getColorRequestPayload() : skipToken,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [orderUpdate, { isLoading: orderUpdateLoading }] =
    useOrderUpdateMutation();

  const [orderConfirm, { isLoading: orderConfirmLoading }] =
    useOrderConfirmMutation();

  const [orderCancel, { isLoading: orderCancelLoading }] =
    useOrderCancelMutation();

  useEffect(() => {
    if (orderId && getOrderSuccess) {
      setOrderDetailData(getOrderData.item);
    }
  }, [orderId, getOrderData, getOrderSuccess, orderItemIndex]);

  useEffect(() => {
    if (inventorySearchSuccess && inventorySearchData.list) {
      setInventorySearchList(inventorySearchData.list);
    }
  }, [inventorySearchSuccess, inventorySearchData?.list]);

  useEffect(() => {
    if (inventoryDesignSuccess && inventoryDesignData.list) {
      setInventoryDesignList(inventoryDesignData.list);
    }
  }, [inventoryDesignSuccess, inventoryDesignData?.list]);

  useEffect(() => {
    if (inventoryFabricCodeSuccess && inventoryFabricCodeData.list) {
      setInventoryFabricCodeList(inventoryFabricCodeData.list);
    }
  }, [inventoryFabricCodeSuccess, inventoryFabricCodeData?.list]);

  useEffect(() => {
    if (inventorySizeSuccess && inventorySizeData.list) {
      setInventorySizeList(inventorySizeData.list);
    }
  }, [inventorySizeSuccess, inventorySizeData?.list]);

  useEffect(() => {
    if (inventoryColorSuccess && inventoryColorData.list) {
      setInventoryColorList(inventoryColorData.list);
    }
  }, [inventoryColorSuccess, inventoryColorData?.list]);

  useEffect(() => {
    if (orderItemIndex !== undefined) {
      let updated = orderDetailData?.items.find(
        (el: any, index: any) => index === orderItemIndex
      );
      setSelectedData(updated);
      if (updated) {
        setSelectedItemData(itemSelectedArray(updated));
        setSelectedDesignData(selectedFieldArray(updated, "designNo"));
        setSelectedFabricData(selectedFieldArray(updated, "fabricCode"));
        setSelectedSizeData(selectedFieldArray(updated, "size"));
        setSelectedColorData(selectedFieldArray(updated, "color"));
      }
    }
  }, [orderItemIndex, orderDetailData]);

  useEffect(() => {
    if (orderDetailData) {
      const items = getCustomItems(orderDetailData);
      setCustomItems(items);
    }
  }, [orderDetailData]);

  const itemSelectedArray = (ele: any) => {
    return [
      {
        color: ele.color,
        design: ele.designNo,
        fabricCode: ele.fabricCode,
        itemCode: ele.itemCode,
        rsp: ele.rsp,
        size: ele.size,
        _id: ele.itemCode,
      },
    ];
  };

  const selectedFieldArray = (item: any, key: any) => {
    return [
      {
        _id: item[key],
        name: item[key],
      },
    ];
  };

  const orderUpdateFlow = async (data: any) => {
    toastSuccess(data.message);
    setEditOrderDetail(false);
    getOrderRefetch();
  };

  const orderConfirmFlow = async (data: any) => {
    toastSuccess(data.message);
    getOrderRefetch();
  };

  const orderCancelFlow = async (data: any) => {
    toastSuccess(data.message);
    getOrderRefetch();
    setShowCancelOrder(false);
  };

  const handleConfirmModal = () => {
    setShowConfirmOrder(true);
  };

  const closeConfirmOrder = () => {
    setShowConfirmOrder(false);
  };

  const handleCancelOrder = () => {
    setShowCancelOrder(true);
  };

  const closeCancelOrder = () => {
    setShowCancelOrder(false);
  };

  const handleCustomItemModal = () => {
    setShowCustomItem(true);
  };

  const closeCustomItemModal = () => {
    setShowCustomItem(false);
  };

  const handleTextChange = (e: any) => {
    setOrderCancelData({ ...orderCancelData, remarks: e.target.value });
  };

  const handleOnBlur = (e: any) => {
    const trimmedValue = e.target.value.trim();

    setOrderCancelData({
      ...orderCancelData,
      remarks: trimmedValue,
    });
  };

  const handleSaveCancelOrder = async (id: any) => {
    const orderCancelResponse = await orderCancel({
      id: id,
      data: orderCancelData,
    })
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (orderCancelResponse && orderCancelResponse?.status) {
      orderCancelFlow(orderCancelResponse);
    }
  };

  const handleEditOrderDetails = () => {
    setOrderItemIndex(0);
    setEditOrderDetail(true);
  };

  const handleUpdateOrderDetails = async (e: any, id: any) => {
    const orderUpdateResponse = await orderUpdate({
      id: id,
      data: {
        items: orderDetailData.items,
      },
    })
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (orderUpdateResponse && orderUpdateResponse?.status) {
      orderUpdateFlow(orderUpdateResponse);
    }
  };

  const handleConfirmOrder = async (id: any) => {
    setShowConfirmOrder(false);
    const orderConfirmResponse = await orderConfirm(id)
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (orderConfirmResponse && orderConfirmResponse?.status) {
      orderConfirmFlow(orderConfirmResponse);
    }
  };

  const handleInputChange = (newValue: string, name: any) => {
    setSearchKeyword(newValue);
    setIsSearchActive({ ...isSearchActive, [name]: !isSearchActive[name] });
    if (!hasTyped && newValue.trim() !== "") {
      setHasTyped(true);
    }
  };

  const handleOnChange = (e: any, name: any, itemIndex: any, custom: any) => {
    if (custom) {
      const customData: any = {};

      if (name === "itemCode") {
        let filteredValue = inventorySearchList?.filter(
          (el: any) => el.itemCode === e.value
        );
        if (filteredValue.length > 0) {
          customData[name] = e.value;
          customData.designNo = filteredValue[0].design;
          customData.fabricCode = filteredValue[0].fabricCode;
          customData.color = filteredValue[0].color;
          customData.size = filteredValue[0].size;
          customData.price = filteredValue[0].rsp || null;
          customData.wsp = filteredValue[0].wsp || null;
        }
      } else {
        customData[name] = e.value;
      }
      let newData = orderDetailData.items.map((ele: any, index: any) => {
        if (itemIndex === index) {
          return {
            ...ele,
            ...customData,
          };
        } else {
          return ele;
        }
      });
      setOrderDetailData({
        ...orderDetailData,
        items: newData,
      });
    } else {
      const updatedData: any = {};
      if (name === "itemCode") {
        let filteredValue = inventorySearchList?.filter(
          (el: any) => el.itemCode === e.value
        );
        if (filteredValue.length > 0) {
          updatedData[name] = e.value;
          updatedData.designNo = filteredValue[0].design;
          updatedData.fabricCode = filteredValue[0].fabricCode;
          updatedData.color = filteredValue[0].color;
          updatedData.size = filteredValue[0].size;
          updatedData.price = filteredValue[0].rsp || null;
          updatedData.wsp = filteredValue[0].wsp || null;
        }
      } else if (name === "designNo") {
        updatedData.itemCode = "";
        updatedData.fabricCode = "";
        updatedData.color = "";
        updatedData.size = "";
        updatedData[name] = e.value;
      } else if (name === "qty") {
        updatedData[name] = e.target.value;
      } else {
        updatedData[name] = e.value;
        updatedData.itemCode = "";
        if (name === "fabricCode") {
          updatedData.color = "";
          updatedData.size = "";
        }
      }

      let updated = orderDetailData.items.map((ele: any, index: any) => {
        if (itemIndex === index) {
          return {
            ...ele,
            ...updatedData,
          };
        } else {
          return ele;
        }
      });
      setOrderDetailData({
        ...orderDetailData,
        items: updated,
      });
    }
  };

  const handleBack = () => {
    navigate(URLS.ORDER);
  };

  const orderConfirmModalButton = [
    {
      text: BUTTON_LABEL.YES,
      action: () => handleConfirmOrder(orderId),
      className: "btn btn theme-btn",
    },
    {
      text: BUTTON_LABEL.NO,
      action: closeConfirmOrder,
      className: "btn btn theme-btn btn-inverse",
    },
  ];

  const customItemModalButton = [
    {
      text: BUTTON_LABEL.OK,
      action: closeCustomItemModal,
      className: "btn btn theme-btn",
    },
  ];

  const orderCancelModalButton = [
    {
      text: BUTTON_LABEL.SUBMIT,
      action: () => handleSaveCancelOrder(orderId),
      className: "btn btn theme-btn",
    },
    {
      text: BUTTON_LABEL.CANCEL,
      action: closeCancelOrder,
      className: "btn btn theme-btn btn-inverse",
    },
  ];

  const toggleShowOrderItem = (index: any) => {
    setOrderItemIndex(index);
  };

  const toggleHideorderItem = () => {
    setOrderItemIndex(undefined);
  };

  const orderStatusBlock = (type: any) => {
    const orderStatus = ORDER_STATUS.find((ele: any) => {
      return ele._id === type;
    });
    return orderStatus ? (
      <p className={`mb-0 ${orderStatus.class} col-md-6`}>
        {LABELS[orderStatus.name]}
      </p>
    ) : (
      "-"
    );
  };

  const measurementStandardBlock = (item: any) => {
    return (
      <div className="value">
        <label htmlFor="">{item?.title}</label>
        <span className="label-box">{item?.value}</span>
      </div>
    );
  };

  const measurementCustomBlock = (title: any, value: any) => {
    if (value) {
      return (
        <div className="col-md-4">
          <div className="slug-custom-dropdown mb-3 open">
            <div className="toggle d-flex align-items-center w-100 justify-content-between">
              <span>{title}</span>
            </div>

            <div className="custom-dropdown-menu">
              <div className="item-card">
                <div className="item-body">
                  <div className="populate-values">
                    <span>{value}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {!getOrderLoading && orderDetailData ? (
        <>
          <div className="full-height">
            <Navbar
              title={`${LABELS.ORDER_INFORMATION_TEXT} - ${orderDetailData.documentNo}`}
              backArrow={true}
              handleBack={handleBack}
            />
            <main className="main-content">
              <div className="pt-3">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <div
                        className={`card custom-card card-full mt-3 has-radius ${
                          !editOrderDetail && "has-input"
                        }`}
                      >
                        <div className="card-body">
                          {authProvider.show("order", "edit") && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <p className="font-20 section-title mb-0">
                                {LABELS.ORDER_DETAILS_TEXT}
                              </p>
                              <div>
                                {orderDetailData.status !==
                                  OrderStatus.CANCELED && (
                                  <>
                                    {orderDetailData.status ===
                                      OrderStatus.PENDING &&
                                      (editOrderDetail ? (
                                        <SimpleButton
                                          className={
                                            "btn theme-btn theme-btn-sm btn-inverse me-2"
                                          }
                                          onClick={(e: any) =>
                                            handleUpdateOrderDetails(e, orderId)
                                          }
                                          text={
                                            orderUpdateLoading ? (
                                              <ButtonLoader />
                                            ) : (
                                              BUTTON_LABEL.SAVE_ORDER
                                            )
                                          }
                                        />
                                      ) : (
                                        <SimpleButton
                                          className={
                                            "btn theme-btn theme-btn-sm me-2"
                                          }
                                          onClick={handleEditOrderDetails}
                                          text={BUTTON_LABEL.EDIT_ORDER}
                                          disabled={
                                            orderConfirmLoading && "disabled"
                                          }
                                        />
                                      ))}

                                    {(orderDetailData.status ===
                                      OrderStatus.PENDING ||
                                      orderDetailData.status ===
                                        OrderStatus.CONFIRM_IN_PROGRESS) && (
                                      <SimpleButton
                                        className={`btn ${
                                          orderDetailData.status !==
                                          OrderStatus.CONFIRM_IN_PROGRESS
                                            ? "success-btn"
                                            : "info-btn"
                                        } theme-btn theme-btn-sm btn-inverse d-inline-block me-2`}
                                        text={
                                          orderConfirmLoading ? (
                                            <ButtonLoader />
                                          ) : orderDetailData.status !==
                                            OrderStatus.CONFIRM_IN_PROGRESS ? (
                                            BUTTON_LABEL.CONFIRM_ORDER
                                          ) : (
                                            BUTTON_LABEL.CONFIRM_IN_PROGRESS
                                          )
                                        }
                                        disabled={
                                          editOrderDetail || orderConfirmLoading
                                            ? "disabled"
                                            : ""
                                        }
                                        onClick={
                                          customItems?.length > 0
                                            ? handleCustomItemModal
                                            : handleConfirmModal
                                        }
                                        // onClick={(e: any) =>
                                        //   handleConfirmOrder(e, orderId)
                                        // }
                                      />
                                    )}
                                    <SimpleButton
                                      className={
                                        "btn theme-btn theme-btn-sm me-2"
                                      }
                                      onClick={handleCancelOrder}
                                      text={BUTTON_LABEL.CANCEL_ORDER}
                                      disabled={
                                        orderConfirmLoading && "disabled"
                                      }
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          )}

                          <div className="card section-card mb-3">
                            <div className="card-body">
                              <div className="row align-items-center">
                                <div className="col-md-4">
                                  <div className="form-group row mb-3">
                                    <div className="col-md-6">
                                      <strong>{LABELS.ORDER_NO_TEXT} - </strong>
                                    </div>
                                    <div className="col-md-6">
                                      {orderDetailData.documentNo
                                        ? orderDetailData.documentNo
                                        : " -"}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center">
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.ORDER_DATE_TEXT} -
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <p className="mb-0 text-muted">
                                        {moment(
                                          orderDetailData.createdAt
                                        ).format(DATE_STANDARD_FORMAT.REGULAR)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.STORE_DELIVERY_DATE_TEXT} -
                                      </label>
                                    </div>
                                    <p className="mb-0 text-muted col-md-6">
                                      {moment(
                                        orderDetailData.deliveryDate
                                      ).format(DATE_STANDARD_FORMAT.REGULAR)}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.CUSTOMER_NAME_TEXT}
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <p className="mb-0 text-muted">
                                        {orderDetailData.firstName +
                                          " " +
                                          orderDetailData.middleName +
                                          " " +
                                          orderDetailData.lastName}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center">
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.DESTINATION_SITE_TEXT} -
                                      </label>
                                    </div>
                                    <p className="mb-0 text-muted col-md-6">
                                      {orderDetailData.destinationSiteCode
                                        ? orderDetailData.destinationSiteCode
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.SHORT_CODE_TEXT}
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <p className="mb-0 text-muted">
                                        {orderDetailData?.store.shortCode}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.PHONE_TEXT}
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <p className="mb-0 text-muted">
                                        {orderDetailData.customerMobile}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center">
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.SO_NO_TEXT} -
                                      </label>
                                    </div>
                                    <p className="mb-0 text-muted col-md-6">
                                      {orderDetailData.ordCode
                                        ? orderDetailData.ordCode
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.CHECKER_ID_TEXT} -
                                      </label>
                                    </div>
                                    <p className="mb-0 text-muted col-md-6">
                                      {orderDetailData.checkerId
                                        ? orderDetailData.checkerId
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.ADDRESS_TEXT}
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <p className="mb-0 text-muted">
                                        {orderDetailData.address
                                          ? orderDetailData.address
                                          : "-"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center">
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.STATUS_TEXT} -
                                      </label>
                                    </div>
                                    {orderStatusBlock(orderDetailData.status)}
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.CREATED_BY_TEXT} -
                                      </label>
                                    </div>
                                    <p className="mb-0 text-muted col-md-6">
                                      {orderDetailData.store?.name
                                        ? orderDetailData.store?.name
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.ADVANCE_AMOUNT_TEXT}
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <p className="mb-0 text-muted">
                                        {orderDetailData.advancePay?.amount
                                          ? orderDetailData.advancePay?.amount
                                          : "-"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.ADVANCE_REMARKS_TEXT}
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <p className="mb-0 text-muted">
                                        {orderDetailData.advancePay?.remarks
                                          ? orderDetailData.advancePay?.remarks
                                          : "-"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {orderDetailData.status === OrderStatus.CANCELED && (
                            <>
                              <h6 className="my-3 section-title">
                                {LABELS.CANCEL_DETAILS_TEXT}
                              </h6>
                              <div className="card section-card mb-3">
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-md-3 col-12">
                                      <div className="form-group mb-0">
                                        <label
                                          htmlFor=""
                                          className="text-black font-weight-600"
                                        >
                                          {LABELS.REMARKS_TEXT}
                                        </label>
                                        <p className="mb-0 text-muted">
                                          {orderDetailData.cancel?.remarks ||
                                            "-"}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-md-3 col-12">
                                      <div className="form-group mb-0">
                                        <label
                                          htmlFor=""
                                          className="text-black font-weight-600"
                                        >
                                          {LABELS.CANCEL_CODE_TEXT}
                                        </label>
                                        <p className="mb-0 text-muted">
                                          {orderDetailData.cancel?.code || "-"}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-md-3 col-12">
                                      <div className="form-group mb-0">
                                        <label
                                          htmlFor=""
                                          className="text-black font-weight-600"
                                        >
                                          {LABELS.CANCEL_NO_TEXT}
                                        </label>
                                        <p className="mb-0 text-muted">
                                          {orderDetailData.cancel?.no || "-"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          <h6 className="my-3 section-title">
                            {LABELS.ITEM_DETAILS_TEXT}
                          </h6>
                          {orderDetailData.items.map((el: any, index: any) => {
                            return (
                              <div className="card section-card mb-3 collapsible-card">
                                <div
                                  className="card-header d-flex align-items-center justify-content-between"
                                  onClick={
                                    orderItemIndex === index
                                      ? () => toggleHideorderItem()
                                      : () => toggleShowOrderItem(index)
                                  }
                                >
                                  <h6 className="section-title card-title mb-0">
                                    <span>
                                      {el.itemCode ? el.itemCode : " -"}
                                    </span>
                                    {el.isCustom && (
                                      <>
                                        &nbsp;-&nbsp;
                                        <span className="ribbon-label">
                                          {LABELS.CUSTOM_TEXT}
                                        </span>
                                      </>
                                    )}
                                  </h6>
                                  <div className="chevron-circle">
                                    <i
                                      className={`${
                                        orderItemIndex === index
                                          ? icons.ICON_CHEVRON_UP
                                          : icons.ICON_CHEVRON_DOWN
                                      } cursor-pointer`}
                                    ></i>
                                  </div>
                                </div>
                                <div
                                  className={`card-body ${
                                    orderItemIndex === index && "show"
                                  }`}
                                >
                                  <div className="row align-items-center">
                                    <div className="col-md-4">
                                      <div className="form-group row mb-3">
                                        <div className="col-md-6">
                                          <strong>
                                            {LABELS.I_CODE_TEXT} -{" "}
                                          </strong>
                                        </div>
                                        <div className="col-md-6">
                                          {editOrderDetail ? (
                                            <SelectionSearch
                                              data={mergeUniqueArrays(
                                                inventorySearchList,
                                                selectedItemData,
                                                "_id"
                                              )}
                                              id={LABELS.ITEM_CODE_TEXT}
                                              name={ACTION_LABEL.ITEM_CODE}
                                              value={el?.itemCode}
                                              onChange={(e: any) =>
                                                handleOnChange(
                                                  e,
                                                  ACTION_LABEL.ITEM_CODE,
                                                  index,
                                                  el.isCustom
                                                )
                                              }
                                              onInputChange={(e: any) =>
                                                handleInputChange(
                                                  e,
                                                  ACTION_LABEL.ITEM_CODE
                                                )
                                              }
                                            />
                                          ) : (
                                            <>
                                              {el.itemCode ? el.itemCode : " -"}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row align-items-center">
                                    <div className="col-md-4">
                                      <div className="form-group row">
                                        <div className="col-md-6">
                                          <label
                                            htmlFor=""
                                            className="text-black font-weight-600"
                                          >
                                            {LABELS.DESIGN_NO_TEXT} -
                                          </label>
                                        </div>
                                        <div className="col-md-6">
                                          {editOrderDetail ? (
                                            <SelectionSearch
                                              data={mergeUniqueArrays(
                                                inventoryDesignList,
                                                selectedDesignData,
                                                "_id"
                                              )}
                                              id={LABELS.DESIGN_NO_TEXT}
                                              name={ACTION_LABEL.DESIGN_NO}
                                              value={el?.designNo}
                                              onChange={(e: any) =>
                                                handleOnChange(
                                                  e,
                                                  ACTION_LABEL.DESIGN_NO,
                                                  index,
                                                  el.isCustom
                                                )
                                              }
                                              onInputChange={(e: any) =>
                                                handleInputChange(
                                                  e,
                                                  ACTION_LABEL.DESIGN_NO
                                                )
                                              }
                                            />
                                          ) : (
                                            <>
                                              {el?.designNo
                                                ? el?.designNo
                                                : "-"}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group row">
                                        <div className="col-md-6">
                                          <label
                                            htmlFor=""
                                            className="text-black font-weight-600"
                                          >
                                            {LABELS.PRICE_TEXT} -
                                          </label>
                                        </div>
                                        <p className="mb-0 text-muted col-md-6">
                                          {el.price}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row align-items-center">
                                    <div className="col-md-4">
                                      <div className="form-group row">
                                        <div className="col-md-6">
                                          <label
                                            htmlFor=""
                                            className="text-black font-weight-600"
                                          >
                                            {LABELS.FABRIC_CODE_TEXT} -
                                          </label>
                                        </div>
                                        <div className="col-md-6">
                                          {editOrderDetail ? (
                                            <SelectionSearch
                                              data={mergeUniqueArrays(
                                                inventoryFabricCodeList,
                                                selectedFabricData,
                                                "_id"
                                              )}
                                              id={LABELS.FABRIC_CODE_TEXT}
                                              name={ACTION_LABEL.FABRIC_CODE}
                                              value={el.fabricCode}
                                              onChange={(e: any) =>
                                                handleOnChange(
                                                  e,
                                                  ACTION_LABEL.FABRIC_CODE,
                                                  index,
                                                  el.isCustom
                                                )
                                              }
                                              onInputChange={(e: any) =>
                                                handleInputChange(
                                                  e,
                                                  ACTION_LABEL.FABRIC_CODE
                                                )
                                              }
                                              disable={
                                                el.designNo
                                                  ? undefined
                                                  : "disable"
                                              }
                                            />
                                          ) : (
                                            <>
                                              {el?.fabricCode
                                                ? el?.fabricCode
                                                : " -"}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group row">
                                        <div className="col-md-6">
                                          <label
                                            htmlFor=""
                                            className="text-black font-weight-600"
                                          >
                                            {LABELS.REMARKS_TEXT} -
                                          </label>
                                        </div>
                                        <p className="mb-0 text-muted col-md-6">
                                          {el.remarks ? el.remarks : "-"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row align-items-center">
                                    <div className="col-md-4">
                                      <div className="form-group row">
                                        <div className="col-md-6">
                                          <label
                                            htmlFor=""
                                            className="text-black font-weight-600"
                                          >
                                            {LABELS.COLOR_TEXT} -
                                          </label>
                                        </div>
                                        <div className="col-md-6">
                                          {editOrderDetail ? (
                                            <SelectionSearch
                                              data={mergeUniqueArrays(
                                                inventoryColorList,
                                                selectedColorData,
                                                "_id"
                                              )}
                                              id={LABELS.COLOR_TEXT}
                                              name={ACTION_LABEL.COLOR}
                                              value={el?.color}
                                              onChange={(e: any) =>
                                                handleOnChange(
                                                  e,
                                                  ACTION_LABEL.COLOR,
                                                  index,
                                                  el.isCustom
                                                )
                                              }
                                              onInputChange={(e: any) =>
                                                handleInputChange(
                                                  e,
                                                  ACTION_LABEL.COLOR
                                                )
                                              }
                                              disable={
                                                editOrderDetail && el.fabricCode
                                                  ? undefined
                                                  : "disable"
                                              }
                                            />
                                          ) : (
                                            <>{el?.color ? el?.color : " -"}</>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group row">
                                        <div className="col-md-6">
                                          <label
                                            htmlFor=""
                                            className="text-black font-weight-600"
                                          >
                                            {LABELS.STORE_DELIVERY_DATE_TEXT} -
                                          </label>
                                        </div>
                                        <p className="mb-0 text-muted col-md-6">
                                          {el?.deliveryDate
                                            ? moment(el?.deliveryDate).format(
                                                DATE_STANDARD_FORMAT.REGULAR
                                              )
                                            : "-"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row align-items-center">
                                    <div className="col-md-4">
                                      <div className="form-group row">
                                        <div className="col-md-6">
                                          <label
                                            htmlFor=""
                                            className="text-black font-weight-600"
                                          >
                                            {LABELS.SIZE_TEXT} -
                                          </label>
                                        </div>
                                        <div className="col-md-6">
                                          {editOrderDetail ? (
                                            <SelectionSearch
                                              data={mergeUniqueArrays(
                                                inventorySizeList,
                                                selectedSizeData,
                                                "_id"
                                              )}
                                              id={LABELS.SIZE_TEXT}
                                              name={ACTION_LABEL.SIZE}
                                              value={el?.size}
                                              onChange={(e: any) =>
                                                handleOnChange(
                                                  e,
                                                  ACTION_LABEL.SIZE,
                                                  index,
                                                  el.isCustom
                                                )
                                              }
                                              onInputChange={(e: any) =>
                                                handleInputChange(
                                                  e,
                                                  ACTION_LABEL.SIZE
                                                )
                                              }
                                              disable={
                                                editOrderDetail && el.fabricCode
                                                  ? undefined
                                                  : "disable"
                                              }
                                            />
                                          ) : (
                                            <>{el?.size ? el?.size : " -"}</>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group row">
                                        <div className="col-md-6">
                                          <label
                                            htmlFor=""
                                            className="text-black font-weight-600"
                                          >
                                            {LABELS.CUSTOMER_DELIVERY_DATE_TEXT}{" "}
                                            -
                                          </label>
                                        </div>
                                        <p className="mb-0 text-muted col-md-6">
                                          {el?.custDeliveryDate
                                            ? moment(
                                                el?.custDeliveryDate
                                              ).format(
                                                DATE_STANDARD_FORMAT.REGULAR
                                              )
                                            : "-"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row align-items-center">
                                    <div className="col-md-4">
                                      <div className="form-group row">
                                        <div className="col-md-6">
                                          <label
                                            htmlFor=""
                                            className="text-black font-weight-600"
                                          >
                                            {LABELS.QTY_TEXT} -
                                          </label>
                                        </div>
                                        <div className="col-md-6">
                                          <TextBox
                                            placeholder={LABELS.QTY_TEXT}
                                            name={ACTION_LABEL.QTY}
                                            value={el?.qty}
                                            onChange={(e: any) =>
                                              handleOnChange(
                                                e,
                                                ACTION_LABEL.QTY,
                                                index,
                                                el.isCustom
                                              )
                                            }
                                            disable={
                                              editOrderDetail ? false : true
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group row">
                                        <div className="col-md-6">
                                          <label
                                            htmlFor=""
                                            className="text-black font-weight-600"
                                          >
                                            {LABELS.SALES_PERSON_TEXT} -
                                          </label>
                                        </div>
                                        <p className="mb-0 text-muted col-md-6">
                                          {el.salesPerson
                                            ? el.salesPerson
                                            : "-"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <h6 className="my-3 section-title">
                                    {LABELS.SUB_ASSEMBLY_ITEM_TEXT}
                                  </h6>
                                  <div className="card section-card mb-3">
                                    <div className="card-body">
                                      <div className="row">
                                        {el?.measurement?.top?.length > 0 &&
                                          el?.measurement?.top?.map(
                                            (topEl: any) => {
                                              return (
                                                <div className="col-md-4">
                                                  <div className="slug-custom-dropdown mb-3 open">
                                                    <div className="toggle d-flex align-items-center w-100 justify-content-between">
                                                      <span>{topEl.type}</span>
                                                    </div>
                                                    <div className="custom-dropdown-menu">
                                                      <div className="item-card">
                                                        <div className="item-body">
                                                          <div className="populate-values">
                                                            {topEl.data.map(
                                                              (el: any) => {
                                                                return measurementStandardBlock(
                                                                  el
                                                                );
                                                              }
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        {el?.measurement?.bottom?.length > 0 &&
                                          el?.measurement?.bottom?.map(
                                            (bottomEl: any) => {
                                              return (
                                                <div className="col-md-4">
                                                  <div className="slug-custom-dropdown mb-3 open">
                                                    <div className="toggle d-flex align-items-center w-100 justify-content-between">
                                                      <span>
                                                        {bottomEl.type}
                                                      </span>
                                                    </div>
                                                    <div className="custom-dropdown-menu">
                                                      <div className="item-card">
                                                        <div className="item-body">
                                                          <div className="populate-values">
                                                            {bottomEl.data.map(
                                                              (el: any) => {
                                                                return measurementStandardBlock(
                                                                  el
                                                                );
                                                              }
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}

                                        {Object.keys(
                                          el?.measurement?.custom
                                        ).map((ele: any) => {
                                          return measurementCustomBlock(
                                            ele,
                                            el?.measurement?.custom[ele]
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                          <h6 className="my-3 section-title">
                            {LABELS.TRACKING_INFORMATION_TEXT}
                          </h6>
                          {LABELS.NOT_FOUND_TEXT}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </>
      ) : (
        <Loader />
      )}

      <CustomModal
        show={showConfirmOrder}
        close={closeConfirmOrder}
        message={MESSAGE.CONFIRM_ORDER_MESSAGE}
        size="md"
        modalTitle={BUTTON_LABEL.CONFIRM_ORDER}
        modalButton={orderConfirmModalButton}
        children={undefined}
        fullscreen={undefined}
        className={""}
        centerClass={""}
        modalTitleIcon={""}
        // modalTitleIcon={icons.ICON_DELETE_V2}
      />

      <CustomModal
        show={showCustomItem}
        close={closeCustomItemModal}
        size={"lg"}
        message={""}
        modalTitle={LABELS.CUSTOM_ITEMS_IN_ORDER_TEXT}
        modalButton={customItemModalButton}
        fullscreen={undefined}
        className={""}
        centerClass={""}
        modalTitleIcon={""}
      >
        <CustomItemsOrder customItems={customItems} />
      </CustomModal>

      <CustomModal
        show={showCancelOrder}
        close={closeCancelOrder}
        size={"lg"}
        message={""}
        modalTitle={LABELS.ITEM_DETAILS_TEXT}
        modalButton={orderCancelModalButton}
        fullscreen={undefined}
        className={""}
        centerClass={""}
        modalTitleIcon={""}
      >
        <CancelOrder
          orderId={orderId}
          orderCancelData={orderCancelData}
          orderCancelLoading={orderCancelLoading}
          handleTextChange={handleTextChange}
          handleBlur={handleOnBlur}
          handleSave={handleSaveCancelOrder}
          handleCancel={closeCancelOrder}
        />
      </CustomModal>
    </>
  );
};

export default OrderDetail;
