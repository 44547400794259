import {
  ACTION_LABEL,
  BUTTON_LABEL,
  icons,
  LABELS,
} from "../../../shared/constants";
import SimpleButton from "../../../shared/components/Button/Button";
import { Offcanvas } from "react-bootstrap";
import Select from "../../../shared/components/Select";
import TextBox from "../../../shared/components/TextBox";
import DatePickerComponent from "../../../shared/components/DatePicker/DatePicker";
import NumberBox from "../../../shared/components/NumberBox";
import {
  ACTIVE_STATE,
  DEBOUNCE_VALUE,
  GENDER_DROP,
  STATUS_DROP,
  USER_TYPE_DROP,
} from "../../../shared/constants/resource";
import { useRolesSearchQuery } from "../../../Features/role/role-slice";
import { useStoreSearchQuery } from "../../../Features/store/store-slice";
import { useCustomerSearchQuery } from "../../../Features/customer/customer-slice";
import { useContext, useState } from "react";
import { DropdownContext } from "../../../context/DropdownContext";
import {
  useInventoryDesignQuery,
  useInventoryFabricCodeQuery,
  useInventoryColorQuery,
} from "../../../Features/inventory/inventory-slice";
import { skipToken } from "@reduxjs/toolkit/query";
import useDebounce from "../../../shared/utils/hooks/useDebounce";
import SelectionSearch from "../../../shared/components/SelectionSearch";

const Filter = (props: any) => {
  const {
    sort,
    filterSelectionError,
    filterObject,
    show,
    onClose,
    handleReset,
    handleApply,
    handleOnBlur,
  } = props;
  const drop = useContext(DropdownContext);
  const { dropdownData: dropData }: any = drop;
  const [searchKeyword, setSearchKeyword]: any = useState("");
  const debouncedSearchKeyword = useDebounce(searchKeyword, DEBOUNCE_VALUE);
  const [isSearchActive, setIsSearchActive]: any = useState({
    category2: false,
    category3: false,
    category6: false,
  });
  const [hasTyped, setHasTyped] = useState(false);

  const isDesignApiCall = () => {
    if (!hasTyped) return false;
    if (isSearchActive.category2) return true;
  };

  const isFabricApiCall = () => {
    if (!hasTyped) return false;
    if (isSearchActive.category3) return true;
  };

  const isColorApiCall = () => {
    if (!hasTyped) return false;
    if (isSearchActive.category6) return true;
  };

  const getDesignRequestPayload = () => {
    if (isSearchActive.category2) {
      return { search: debouncedSearchKeyword || "" };
    }
  };

  const getFabricRequestPayload = () => {
    if (isSearchActive.category3) {
      return { search: debouncedSearchKeyword || "" };
    }
  };

  const getColorRequestPayload = () => {
    if (isSearchActive.category6) {
      return { search: debouncedSearchKeyword || "" };
    }
  };

  const { data: roleSearchData, isLoading: rolesLoading } =
    useRolesSearchQuery("");

  const { data: storeSearchData, isLoading: storeLoading } =
    useStoreSearchQuery("");

  const { data: customerSearchData, isLoading: customerSearchLoading } =
    useCustomerSearchQuery("");

  const { data: inventoryDesignData, isLoading: inventoryDesignLoading } =
    useInventoryDesignQuery(
      isDesignApiCall() ? getDesignRequestPayload() : skipToken,
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const {
    data: inventoryFabricCodeData,
    isLoading: inventoryFabricCodeLoading,
  } = useInventoryFabricCodeQuery(
    isFabricApiCall() ? getFabricRequestPayload() : skipToken,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: inventoryColorData, isLoading: inventoryColorLoading } =
    useInventoryColorQuery(
      isColorApiCall() ? getColorRequestPayload() : skipToken,
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const getOptions = (filter: any) => {
    switch (filter.name) {
      case ACTION_LABEL.ROLE:
        if (rolesLoading) {
          return [];
        }
        return roleSearchData?.list || [];
      case ACTION_LABEL.STORE:
        if (storeLoading) {
          return [];
        }
        return storeSearchData?.list || [];
      case ACTION_LABEL.CUSTOMER:
        if (customerSearchLoading) {
          return [];
        }
        return customerSearchData?.list || [];
      case ACTION_LABEL.STATE:
        if (!dropData.states) {
          return [];
        }
        return dropData.states || [];
      case ACTION_LABEL.CITY:
        if (!dropData.cities) {
          return [];
        }
        return dropData.cities || [];
      case ACTION_LABEL.DIVISION:
        if (!dropData.divisions) {
          return [];
        }
        return dropData.divisions || [];
      case ACTION_LABEL.SECTION:
        if (!dropData.sections) {
          return [];
        }
        return dropData.sections || [];
      case ACTION_LABEL.DEPARTMENT:
        if (!dropData.departments) {
          return [];
        }
        return dropData.departments || [];
      case `${ACTION_LABEL.CATEGORY}1`:
        if (!dropData.category1) {
          return [];
        }
        return dropData.category1 || [];
      case `${ACTION_LABEL.CATEGORY}2`:
        if (inventoryDesignLoading) {
          return [];
        }
        return inventoryDesignData?.list || [];
      case `${ACTION_LABEL.CATEGORY}3`:
        if (inventoryFabricCodeLoading) {
          return [];
        }
        return inventoryFabricCodeData?.list || [];
      case `${ACTION_LABEL.CATEGORY}4`:
        if (!dropData.category4) {
          return [];
        }
        return dropData.category4 || [];
      case `${ACTION_LABEL.CATEGORY}5`:
        if (!dropData.category5) {
          return [];
        }
        return dropData.category5 || [];
      case `${ACTION_LABEL.CATEGORY}6`:
        if (inventoryColorLoading) {
          return [];
        }
        return inventoryColorData?.list || [];
      case ACTION_LABEL.ORDER_STATUS:
        return STATUS_DROP;
      case ACTION_LABEL.GENDER:
        return GENDER_DROP;
      case ACTION_LABEL.ISACTIVE:
        return ACTIVE_STATE;
      case ACTION_LABEL.USERTYPE:
        return USER_TYPE_DROP;
      default:
        return [];
    }
  };

  const handleInputChange = (newValue: string, name: any) => {
    setSearchKeyword(newValue);
    if (newValue.length === 0 || newValue.length > 1) {
      setIsSearchActive({ ...isSearchActive, [name]: true });
    } else if (newValue.length === 1) {
      setIsSearchActive({ ...isSearchActive, [name]: false });
    }
    if (!hasTyped && newValue.trim() !== "") {
      setHasTyped(true);
    }
  };

  const renderFilterBlock = (filter: any) => {
    if (filter.elementType === "select") {
      return (
        <div className="col-12">
          <div className="form-group">
            <Select
              data={getOptions(filter) || []}
              label={filter.label}
              id={filter.label}
              name={filter.name}
              value={sort[filter?.name]}
              onChange={(e: any) => filter.action(e.target, e, filter.type)}
            />
          </div>
        </div>
      );
    }

    if (filter.elementType === "selectSearch") {
      return (
        <div className="col-12">
          <div className="form-group">
            <SelectionSearch
              data={getOptions(filter) || []}
              label={filter.label}
              id={filter.label}
              name={filter.name}
              value={sort[filter?.name]}
              onChange={(e: any) => filter.action(e, filter.name)}
              onInputChange={
                filter.isSearch
                  ? (e: any) => handleInputChange(e, filter.name)
                  : undefined
              }
            />
          </div>
        </div>
      );
    }

    if (filter.elementType === "text") {
      return (
        <div className="col-12">
          <div className="form-group">
            <TextBox
              name={filter.name}
              placeholder={filter.label}
              id={filter.label}
              label={filter.label}
              onChange={filter.action}
              onBlur={handleOnBlur}
              value={sort[filter.name] || ""}
            />
          </div>
        </div>
      );
    }

    if (filter.elementType === "number") {
      return (
        <div className="col-12">
          <div className="form-group">
            <NumberBox
              name={filter.name}
              placeholder={filter.label}
              id={filter.label}
              label={filter.label}
              onChange={filter.action}
              value={sort[filter.name] || ""}
              min={"0"}
              max={100}
            />
          </div>
        </div>
      );
    }

    if (filter.elementType === "createdAt") {
      return (
        <div className="col-12">
          <div className="form-group">
            <DatePickerComponent
              label={filter.label}
              name={filter.name}
              onChange={filter.action}
              placeholder={"DD/MM/YYYY"}
              format="dd/MM/yyyy"
              placement="bottom"
              startDate={
                sort?.[filter.name]?.from
                  ? new Date(sort?.[filter.name]?.from)
                  : null
              }
              endDate={
                sort?.[filter.name]?.to
                  ? new Date(sort?.[filter.name]?.to)
                  : null
              }
              selectsRange
              isClearable
            />
          </div>
        </div>
      );
    }
  };

  return (
    <Offcanvas
      show={show}
      onHide={onClose}
      // backdrop="true"
      placement="end"
      className="filter-bar"
    >
      <div className="filter-header">
        <h5>{LABELS.FILTER_TEXT}</h5>
        <div className="close-btn" onClick={onClose}>
          <i className={icons.ICON_CLOSE} />
        </div>
      </div>
      <Offcanvas.Body className="filter-body">
        {filterObject?.map((item: any, index: any) => {
          return renderFilterBlock(item);
        })}
        {filterSelectionError && (
          <span className="text-error">{filterSelectionError}</span>
        )}
        <div className="col-md-12 text-center mt-5">
          <SimpleButton
            text={BUTTON_LABEL.APPLY}
            className="btn theme-btn theme-btn-sm me-2"
            onClick={handleApply}
          />
          <SimpleButton
            className="btn theme-btn theme-btn-sm btn-inverse me-2"
            text={BUTTON_LABEL.RESET}
            onClick={handleReset}
          />
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default Filter;
