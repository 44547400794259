import Loader from "../../../../shared/components/Loader";
import TextBox from "../../../../shared/components/TextBox";
import { ACTION_LABEL, LABELS } from "../../../../shared/constants";

const StoreDetail = (props: any) => {
  const { storeDetailData, getStoreLoading } = props;

  return (
    <>
      {!getStoreLoading && storeDetailData ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.CUID_TEXT}
                  placeholder={LABELS.CUID_TEXT}
                  name={ACTION_LABEL.CUID}
                  value={storeDetailData.CUID}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.NAME_TEXT}
                  placeholder={LABELS.NAME_TEXT}
                  name={ACTION_LABEL.NAME}
                  value={storeDetailData.name}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={`${LABELS.PHONE_NO_TEXT} 1`}
                  placeholder={`${LABELS.PHONE_NO_TEXT} 1`}
                  name={`${ACTION_LABEL.PHONE_NO}1`}
                  value={storeDetailData.phoneNo1}
                  disable
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.TARGET_SALES_TEXT}
                  placeholder={LABELS.TARGET_SALES_TEXT}
                  name={ACTION_LABEL.TARGET_SALES}
                  value={storeDetailData.targetSales}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.CUSTOMER_TEXT}
                  placeholder={LABELS.CUSTOMER_TEXT}
                  name={ACTION_LABEL.CUSTOMER}
                  value={storeDetailData.customer}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.GST_STATE_NAME_TEXT}
                  placeholder={LABELS.GST_STATE_NAME_TEXT}
                  name={ACTION_LABEL.GST_STATE_NAME}
                  value={storeDetailData.gstStateName}
                  disable
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.SHORT_CODE_TEXT}
                  placeholder={LABELS.SHORT_CODE_TEXT}
                  name={ACTION_LABEL.SHORTCODE}
                  value={storeDetailData.shortCode}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.ADDRESS_TEXT}
                  placeholder={LABELS.ADDRESS_TEXT}
                  name={ACTION_LABEL.ADDRESS}
                  value={storeDetailData.address}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                {/* <TextBox
                  label={`${LABELS.PHONE_NO_TEXT} 2`}
                  placeholder={`${LABELS.PHONE_NO_TEXT} 2`}
                  name={`${ACTION_LABEL.PHONE_NO}2`}
                  value={storeDetailData.phoneNo2}
                /> */}
                <TextBox
                  label={LABELS.MOBILE_TEXT}
                  placeholder={LABELS.MOBILE_TEXT}
                  name={ACTION_LABEL.MOBILE}
                  value={storeDetailData.mobile}
                  disable
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.DOC_IDENTIFICATION_NO_TEXT}
                  placeholder={LABELS.DOC_IDENTIFICATION_NO_TEXT}
                  name={ACTION_LABEL.DOC_IDENTIFICATION_NO}
                  value={storeDetailData.docIdentificationNo}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.STATE_TEXT}
                  placeholder={LABELS.STATE_TEXT}
                  name={ACTION_LABEL.STATE}
                  value={storeDetailData.state}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.EMAIL_ID_TEXT}
                  placeholder={LABELS.EMAIL_ID_TEXT}
                  name={ACTION_LABEL.EMAIL}
                  value={storeDetailData.email}
                  disable
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.ORGANIZATION_UNIT_TEXT}
                  placeholder={LABELS.ORGANIZATION_UNIT_TEXT}
                  name={ACTION_LABEL.SITE_TYPE}
                  value={storeDetailData.siteType}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.CITY_TEXT}
                  placeholder={LABELS.CITY_TEXT}
                  name={ACTION_LABEL.CITY}
                  value={storeDetailData.city}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.TAX_REGION_TEXT}
                  placeholder={LABELS.TAX_REGION_TEXT}
                  name={ACTION_LABEL.TAX_REGION}
                  value={storeDetailData.taxRegion}
                  disable
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.CUSTOMER_NAME_TEXT}
                  placeholder={LABELS.CUSTOMER_NAME_TEXT}
                  name={ACTION_LABEL.PRICE_TYPE}
                  value={storeDetailData.priceType}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.PIN_TEXT}
                  placeholder={LABELS.PIN_TEXT}
                  name={ACTION_LABEL.PIN}
                  value={storeDetailData.pin}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.SYNC_DATE_AND_TIME_TEXT}
                  placeholder={LABELS.SYNC_DATE_AND_TIME_TEXT}
                  name={ACTION_LABEL.SYNC_DATE_AND_TIME}
                  value={storeDetailData.syncDateAndTime}
                  disable
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.CODE_TEXT}
                  placeholder={LABELS.CODE_TEXT}
                  name={ACTION_LABEL.CODE}
                  value={storeDetailData.code}
                  disable
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default StoreDetail;
