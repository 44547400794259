import { API_URL } from "../../shared/constants";
import { emptySplitApi } from "../emptySplit-api";

export const userProfileApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    userProfileUpdate: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `${API_URL.ACCOUNT}/${API_URL.PROFILE}/update`,
          method: "post",
          body: data,
        };
      },
    }),
    logoUpload: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.COMMON}/upload-image`,
          method: "post",
          body: data,
        };
      },
    }),
    userProfileGet: builder.query<any, any>({
      query: (id: any) => {
        return {
          url: `${API_URL.ACCOUNT}/${API_URL.PROFILE}`,
          method: "post",
        };
      },
    }),
    changePassword: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `${API_URL.ACCOUNT}/change-password`,
          method: "post",
          body: data,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useUserProfileUpdateMutation,
  useUserProfileGetQuery,
  useLogoUploadMutation,
  useChangePasswordMutation,
} = userProfileApi;
