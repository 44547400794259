export const mergeUniqueArrays = (
  arr1: any[] = [],
  arr2: any[] = [],
  fieldName: string
) => {
  if (arr2.length === 0) {
    return arr1.filter(
      (value, index, self) =>
        index === self.findIndex((item) => item[fieldName] === value[fieldName])
    );
  }

  arr1.forEach((ele: any) => {
    let findArray = arr2.find(
      (ele1: any) => ele1[fieldName] === ele[fieldName]
    );
    if (!findArray) {
      arr2.push(ele);
    }
  });

  return arr2;
};
