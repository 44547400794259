import { useEffect, useMemo, useState } from "react";
import Navbar from "../../Navbar/NavbarComponent";
import {
  ACTION_LABEL,
  BUTTON_LABEL,
  icons,
  LABELS,
  MESSAGE,
  SEARCH_DATA,
  toastError,
  toastSuccess,
} from "../../../shared/constants";
import Search from "../../../shared/components/Search";
import Table from "../../../shared/components/Table";
import { useDeleteModal } from "../../../shared/utils/hooks/useDeleteModal";
import {
  useStoreCoordinatorCreateMutation,
  useStoreCoordinatorDeleteMutation,
  useStoreCoordinatorGetQuery,
  useStoreCoordinatorListQuery,
  useStoreCoordinatorUpdateMutation,
} from "../../../Features/user/store-coordinators-slice";
import CustomSwitch from "../../../shared/components/CustomSwitch";
import { listCheck } from "../../../shared/helperFunc/listCheck";
import CustomModal from "../../../shared/components/CustomModal";
import AddStoreCoordinator from "./AddStoreCoordinator";
import StoreCoordinatorDetail from "./StoreCoordinatorDetail";
import { skipToken } from "@reduxjs/toolkit/query";
import { storeCoordinatorValidation } from "../../../shared/validation/storeCoordinatorValidation";
import SimpleButton from "../../../shared/components/Button/Button";
import authProvider from "../../../shared/config/authProvider";
import Filter from "../../Common/Filter/Filter";
import { useFilter } from "../../../shared/utils/hooks/useFilter";
import { DEBOUNCE_VALUE, USER_TYPE } from "../../../shared/constants/resource";
import { convertToAppropriateType } from "../../../shared/helperFunc/convertToAppropriateType";
import useDebounce from "../../../shared/utils/hooks/useDebounce";

const StoreCoordinator = () => {
  const [showDetail, setShowDetail] = useState(false);
  const [showStoreCoordinatorModal, setShowStoreCoordinatorModal] =
    useState(false);
  const [sort, setSort] = useState(SEARCH_DATA);
  const [search, setSearch] = useState(false);
  const [count, setCount] = useState();
  const [multiDelete, setMultiDelete] = useState(false);
  const [storeCoordinatorList, setStoreCoordinatorList] = useState([]);
  const [storeCoordinatorId, setStoreCoordinatorId]: any = useState();
  const [storeCoordinatorDetailData, setStoreCoordinatorDetailData] =
    useState();
  const [addStoreCoordinatorData, setAddStoreCoordinatorData]: any = useState({
    name: "",
    username: "",
    email: "",
    phone: "",
    designation: "",
    address: "",
    state: "",
    city: "",
    password: "",
    type: "",
    store: "",
    isActive: true,
  });
  const [editMode, setEditMode] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [errors, setErrors] = useState({});

  const [
    showModal,
    deletedName,
    deleteId,
    deleteShowModal,
    deleteCloseModal,
    deleteShowModals,
  ] = useDeleteModal();
  const [handleFilter] = useFilter(sort, setSort);
  const [showFilter, setShowFilter] = useState(false);
  const [filterCalled, setFilterCalled]: any = useState(false);
  const [filterSelectionError, setFilterSelectionError]: any = useState();
  const [filterValues, setFilterValues] = useState<any>({});
  const [defaultFilterValues, setDefaultFilterValues] = useState<any>({});
  const [searchKeyword, setSearchKeyword] = useState(sort.search);
  const debouncedSearchKeyword = useDebounce(searchKeyword, DEBOUNCE_VALUE);

  const {
    data,
    isSuccess,
    error,
    refetch: storeCoordinatorRefetch,
    isFetching: storeCoordinatorListFetching,
  } = useStoreCoordinatorListQuery(
    debouncedSearchKeyword
      ? { ...sort, search: debouncedSearchKeyword, skip: 0 }
      : sort,
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const [storeCoordinatorCreate, { isLoading: storeCoordinatorCreateLoading }] =
    useStoreCoordinatorCreateMutation();

  const [storeCoordinatorUpdate, { isLoading: storeCoordinatorUpdateLoading }] =
    useStoreCoordinatorUpdateMutation();

  const [storeCoordinatorDelete, { isLoading: storeCoordinatorDeleteLoading }] =
    useStoreCoordinatorDeleteMutation();

  const {
    data: getStoreCoordinatorData,
    isLoading: getStoreCoordinatorLoading,
    isSuccess: getStoreCoordinatorSuccess,
  } = useStoreCoordinatorGetQuery(storeCoordinatorId || skipToken, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (isSuccess) {
      setStoreCoordinatorList(data.list);
      setCount(data.count);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (storeCoordinatorId && getStoreCoordinatorSuccess) {
      setStoreCoordinatorDetailData({
        ...getStoreCoordinatorData.item,
        store: getStoreCoordinatorData?.store?.name,
      });
    }
  }, [storeCoordinatorId, getStoreCoordinatorData, getStoreCoordinatorSuccess]);

  const handleApplyFilter = () => {
    // if (Object.keys(sort.filter).length > 0) {
    handleFilter();
    setFilterCalled(!filterCalled);
    setSort({ ...sort, filter: filterValues, filterFlag: true, skip: 0 });
    setShowFilter(!showFilter);
    // } else {
    //   setFilterSelectionError("*Please select atlease one filter.");
    // }
  };

  const handleCallFilter = () => {
    setShowFilter(!showFilter);
  };

  const resetFilter = () => {
    setFilterValues({});
    setSort({ ...sort, filter: defaultFilterValues, filterFlag: false });
    setFilterCalled(!filterCalled);
    setFilterSelectionError("");
    setShowFilter(!showFilter);
  };

  const closeFilter = () => {
    setFilterValues(defaultFilterValues);
    setFilterCalled(!filterCalled);
    setFilterSelectionError("");
    setShowFilter(false);
  };

  const closeStoreCoordinatorModal = () => {
    setShowStoreCoordinatorModal(false);
    setStoreCoordinatorId("");
    setAddStoreCoordinatorData({});
    setEditMode(false);
    setResetPassword(false);
    setErrors({});
  };

  const handleSearch = () => {
    setSearch(!search);
    setSort({ ...sort, skip: 0 });
  };

  const handleSearchTextChange = (e: any) => {
    setSearchKeyword(e.target.value);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const storeCoordinatorAddFlow = async (data: any) => {
    toastSuccess(data.message);
    setStoreCoordinatorId("");
    setEditMode(false);
    setErrors({});
    setShowStoreCoordinatorModal(false);
    setAddStoreCoordinatorData({});
    storeCoordinatorRefetch();
  };

  const storeCoordinatorUpdateFlow = async (data: any) => {
    toastSuccess(data.message);
    setStoreCoordinatorId("");
    setEditMode(false);
    setErrors({});
    setShowStoreCoordinatorModal(false);
    setAddStoreCoordinatorData({});
    storeCoordinatorRefetch();
  };

  const storeCoordinatorDeleteFlow = async (data: any) => {
    toastSuccess(data.message);
    deleteCloseModal();
    deleteShowModal([]);
    removeMultiDelete();
    setShowStoreCoordinatorModal(false);
    storeCoordinatorRefetch();
  };

  const handleSwitchChange = async (id: any, isActive: any) => {
    const salesUpdateResponse = await storeCoordinatorUpdate({
      id: id,
      data: { isActive: !isActive },
    })
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (salesUpdateResponse?.status) {
      storeCoordinatorUpdateFlow(salesUpdateResponse);
    }
  };

  const closeDetail = () => {
    setStoreCoordinatorId("");
    setShowDetail(false);
  };

  const handleOnChange = (e: any) => {
    setAddStoreCoordinatorData({
      ...addStoreCoordinatorData,
      [e.target.name]: e.target.value,
    });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleOnBlur = (e: any) => {
    const trimmedValue = e.target.value.trim();

    setAddStoreCoordinatorData({
      ...addStoreCoordinatorData,
      [e.target.name]: trimmedValue,
    });
  };

  const handleSave = async () => {
    const { formErrors, formValid } = storeCoordinatorValidation(
      addStoreCoordinatorData,
      editMode ? resetPassword : true
    );
    if (formValid) {
      if (editMode) {
        const storeCoordinatorUpdateResponse = await storeCoordinatorUpdate({
          id: storeCoordinatorId,
          data: addStoreCoordinatorData,
        })
          .unwrap()
          .catch((err: any) => {
            toastError(err.data?.errors[0]?.msg);
          });
        if (
          storeCoordinatorUpdateResponse &&
          storeCoordinatorUpdateResponse.status
        ) {
          storeCoordinatorUpdateFlow(storeCoordinatorUpdateResponse);
        }
      } else {
        const storeCoordinatorAddResponse = await storeCoordinatorCreate(
          addStoreCoordinatorData
        )
          .unwrap()
          .catch((err: any) => {
            toastError(err.data?.errors[0]?.msg);
          });
        if (
          storeCoordinatorAddResponse &&
          storeCoordinatorAddResponse?.status
        ) {
          storeCoordinatorAddFlow(storeCoordinatorAddResponse);
        }
      }
    } else {
      setErrors(formErrors);
    }
  };

  const addstoreCoordinatorModalButton = [
    {
      text: BUTTON_LABEL.SAVE,
      action: handleSave,
      className: "btn theme-btn",
      isLoading: editMode
        ? storeCoordinatorUpdateLoading
        : storeCoordinatorCreateLoading,
    },
    {
      text: BUTTON_LABEL.CANCEL,
      action: closeStoreCoordinatorModal,
      className: "btn btn theme-btn",
    },
  ];

  const storeCoordinatorDetailModalButton = [
    {
      text: BUTTON_LABEL.CANCEL,
      action: closeDetail,
      className: "btn btn theme-btn btn-inverse",
    },
  ];

  const handleBlur = (e: any) => {
    const trimmedValue = e.target.value.trim();

    setFilterValues({
      ...filterValues,
      [e.target.name]: trimmedValue,
    });
  };

  const handleTextChange = (e: any) => {
    setFilterValues({
      ...filterValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect = (item: any, e: any, type: string) => {
    let selectedValue = convertToAppropriateType(item?.value, type);

    if (item?.value) {
      setFilterValues({
        ...filterValues,
        [e.target.name]: selectedValue,
      });
    } else {
      let updatedFilter: any = { ...filterValues };
      delete updatedFilter[e.name];
      setFilterValues(updatedFilter);
    }
    setFilterSelectionError("");
  };

  const handleDate = (dates: any, filterName: any) => {
    const [start, end] = dates;

    const fromDate = start ? new Date(start).toISOString() : null;
    let toDate = null;
    if (end) {
      var datestring = new Date(end).toISOString().split("T")[0];
      toDate = new Date(datestring + "T23:59:59.000+05:30").toISOString();
    }

    setFilterValues({
      ...filterValues,
      [filterName]: { from: fromDate, to: toDate },
    });
  };

  const filterContent = (
    handleTextChange: any,
    handleSelect: any,
    handleDate: any
  ) => {
    return [
      {
        label: LABELS.STORE_TEXT,
        name: ACTION_LABEL.STORE,
        elementType: "select",
        action: handleSelect,
        type: "",
        operator: "is from",
      },
      {
        label: LABELS.USER_TYPE_TEXT,
        name: ACTION_LABEL.USERTYPE,
        elementType: "select",
        action: handleSelect,
        type: "number",
        operator: "is from",
      },
      {
        label: LABELS.STATE_TEXT,
        name: ACTION_LABEL.STATE,
        elementType: "text",
        action: handleTextChange,
        type: "",
        operator: "is from",
      },
      {
        label: LABELS.CITY_TEXT,
        name: ACTION_LABEL.CITY,
        elementType: "text",
        action: handleTextChange,
        type: "",
        operator: "is from",
      },
      {
        label: LABELS.CREATED_AT_TEXT,
        name: ACTION_LABEL.CREATED_AT,
        elementType: "createdAt",
        action: handleDate,
        type: "",
        operator: "is from",
      },
      {
        label: LABELS.STATUS_TEXT,
        name: ACTION_LABEL.ISACTIVE,
        elementType: "select",
        action: handleSelect,
        type: "",
        operator: "is from",
      },
    ];
  };

  const columns = useMemo(
    () => [
      {
        header: LABELS.FULLNAME_TEXT,
        accessorKey: ACTION_LABEL.NAME,
        isShow: true,
      },
      {
        header: LABELS.EMAIL_TEXT,
        accessorKey: ACTION_LABEL.EMAIL,
        isShow: true,
      },
      {
        header: LABELS.PHONE_NO_TEXT,
        accessorKey: ACTION_LABEL.PHONE,
        isShow: true,
      },
      {
        header: LABELS.USERCODE_TEXT,
        accessorKey: ACTION_LABEL.USERNAME,
        isShow: true,
      },
      {
        header: LABELS.USER_TYPE_TEXT,
        cell: (row: any) => {
          return USER_TYPE[row?.row?.original?.type];
        },
        isShow: true,
      },
      {
        header: LABELS.STORE_TEXT,
        accessorKey: `${ACTION_LABEL.STORE}.${ACTION_LABEL.NAME}`,
        isShow: true,
      },
      {
        header: LABELS.STATUS_TEXT,
        cell: ({ row }: any) => CustomSwitch(row, handleSwitchChange),
        isShow: true,
      },
    ],
    [sort]
  );

  const deleteModal = (handleDelete: any, closeModal: any, isLoading: any) => {
    return [
      {
        text: BUTTON_LABEL.YES,
        action: handleDelete,
        className: "btn theme-btn theme-btn-sm w-auto",
        isLoading: isLoading,
      },
      {
        text: BUTTON_LABEL.NO,
        action: closeModal,
        className: "btn theme-btn theme-btn-sm btn-inverse w-auto",
      },
    ];
  };

  const handleDelete = async () => {
    const storeCoordinatorDeleteResponse = await storeCoordinatorDelete({
      _id: deleteId,
    })
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (
      storeCoordinatorDeleteResponse &&
      storeCoordinatorDeleteResponse?.status
    ) {
      storeCoordinatorDeleteFlow(storeCoordinatorDeleteResponse);
    }
  };

  const closeModal = () => {
    deleteCloseModal();
    deleteShowModal([]);
    removeMultiDelete();
  };

  const deleteModalButton = deleteModal(
    handleDelete,
    closeModal,
    storeCoordinatorDeleteLoading
  );

  const setCheckBox = (flag: any) => {
    let updated = listCheck(
      JSON.parse(JSON.stringify(storeCoordinatorList)),
      flag
    );
    return updated;
  };

  const checkSelectedData = (data: any) => {
    return data.filter((ele: any) => {
      return ele.isSelected;
    });
  };

  const checked = (e: any, index: any) => {
    setMultiDelete(true);
    let data = JSON.parse(JSON.stringify(storeCoordinatorList));

    if (e.target.checked) {
      data[index].isSelected = true;
    } else {
      data[index].isSelected = false;
    }
    setStoreCoordinatorList(data);
    const visibleName = checkSelectedData(data);
    deleteShowModal(visibleName);
  };

  const removeMultiDelete = () => {
    let unSelected = setCheckBox(false);
    setStoreCoordinatorList(unSelected);
    const visibleName = checkSelectedData(unSelected);
    deleteShowModal(visibleName);
  };

  const actionMultiDelete = () => {
    deleteShowModals();
    const visibleName = checkSelectedData(storeCoordinatorList);
    deleteShowModal(visibleName);
  };

  const allCheck = (e: any) => {
    setMultiDelete(true);
    let data = [];
    data = setCheckBox(e.target.checked);
    const visibleName = checkSelectedData(data);
    deleteShowModal(visibleName);

    setStoreCoordinatorList(data);
  };

  const handleView = (row: any) => {
    setStoreCoordinatorId(row._id);
    setShowDetail(true);
  };

  const handleStoreCoordinatorModal = () => {
    setShowStoreCoordinatorModal(true);
  };

  const handleResetPassword = () => {
    setResetPassword(!resetPassword);
  };

  const handleEdit = (row: any) => {
    setStoreCoordinatorId(row._id);
    setShowStoreCoordinatorModal(true);
  };

  const handleShowModal = (row: any) => (
    deleteShowModals(true), deleteShowModal([row])
  );

  const tableAction = (
    handleView: any,
    handleEdit: any,
    handleShowModal: any
  ) => {
    return [
      {
        elementType: "button",
        text: BUTTON_LABEL.VIEW,
        className: `${`icon ${icons.ICON_VIEW}`} mx-1 `,
        icon: `icon ${icons.ICON_VIEW}`,
        action: handleView,
        access: authProvider.show("storeCoordinator", "view"),
      },
      {
        elementType: "button",
        text: BUTTON_LABEL.EDIT,
        className: `${`icon ${icons.ICON_EDIT}`} mx-1 `,
        action: handleEdit,
        access: authProvider.show("storeCoordinator", "edit"),
      },
      {
        elementType: "button",
        text: BUTTON_LABEL.DELETE,
        className: `${`icon ${icons.ICON_DELETE}`} mx-1 `,
        action: handleShowModal,
        access: authProvider.show("storeCoordinator", "edit"),
      },
    ];
  };

  const action = tableAction(handleView, handleEdit, handleShowModal);

  return (
    <>
      <div className="full-height">
        <Navbar title={LABELS.STORE_COORDINATOR_TEXT} />
        <main className="main-content">
          <div className="pt-3">
            <div className="container-fluid">
              <div className="row table-top-actions">
                <div className="col-12 col-md-12 d-flex justify-content-end">
                  <div className="d-flex align-items-center row w-100 mx-m-0">
                    <Search
                      placeholder={LABELS.SEARCH_TEXT}
                      name={ACTION_LABEL.SEARCH}
                      value={sort.search}
                      onChange={handleSearchTextChange}
                      onKeyDown={handleKeyDown}
                      onClick={handleSearch}
                    />
                    {authProvider.show("storeCoordinator", "edit") && (
                      <SimpleButton
                        className={
                          "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                        }
                        onClick={handleStoreCoordinatorModal}
                        btnIcon={icons.ICON_ADD}
                        text={BUTTON_LABEL.ADD}
                      />
                    )}

                    <SimpleButton
                      className={`me-2 btn theme-btn theme-btn-sm ${
                        Object.keys(sort.filter).length > 0 ? "" : "btn-inverse"
                      } filter-btn`}
                      btnIcon={icons.ICON_FILTER}
                      btnIconClass={"me-2 d-inline-block"}
                      onClick={handleCallFilter}
                    />
                  </div>
                </div>
              </div>
              <div className="card custom-card card-full mt-3">
                <div className="card-body p-0">
                  <Table
                    data={storeCoordinatorList}
                    columns={columns}
                    select={true}
                    action={action}
                    sort={sort}
                    setSort={setSort}
                    allCheck={allCheck}
                    allCheckedCheckbox={
                      deleteId.length === storeCoordinatorList.length
                    }
                    checked={checked}
                    coloumnDrop={false}
                    count={count}
                    isFetching={storeCoordinatorListFetching}
                    deleteId={deleteId}
                    removeMultiDelete={removeMultiDelete}
                    actionMultiDelete={actionMultiDelete}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <CustomModal
        show={showModal}
        close={closeModal}
        message={`${MESSAGE.DELETE_MESSAGE} ${deletedName} Store Coordinator?`}
        size="md"
        modalTitle={LABELS.DELETE_STORE_COORDINATOR_TEXT}
        modalButton={deleteModalButton}
        children={undefined}
        fullscreen={undefined}
        className={""}
        centerClass={""}
        modalTitleIcon={""}
      />

      <CustomModal
        show={showStoreCoordinatorModal}
        close={closeStoreCoordinatorModal}
        size={"lg"}
        message={""}
        modalTitle={
          editMode
            ? LABELS.EDIT_STORE_COORDINATOR_TEXT
            : LABELS.ADD_STORE_COORDINATOR_TEXT
        }
        modalButton={addstoreCoordinatorModalButton}
        fullscreen={undefined}
        className={""}
        centerClass={""}
        modalTitleIcon={""}
      >
        <AddStoreCoordinator
          storeCoordinatorId={storeCoordinatorId}
          editMode={editMode}
          setEditMode={setEditMode}
          addStoreCoordinatorData={addStoreCoordinatorData}
          setAddStoreCoordinatorData={setAddStoreCoordinatorData}
          resetPassword={resetPassword}
          setResetPassword={setResetPassword}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleResetPassword={handleResetPassword}
          errors={errors}
        />
      </CustomModal>

      <CustomModal
        show={showDetail}
        close={closeDetail}
        size={"lg"}
        message={""}
        modalTitle={LABELS.STORE_COORDINATOR_DETAILS_TEXT}
        modalButton={storeCoordinatorDetailModalButton}
        fullscreen={undefined}
        className={"light-modal inventory-detail-modal"}
        centerClass={""}
        modalTitleIcon={""}
      >
        <StoreCoordinatorDetail
          storeCoordinatorDetailData={storeCoordinatorDetailData}
          getStoreCoordinatorLoading={getStoreCoordinatorLoading}
        />
      </CustomModal>

      <Filter
        sort={filterValues}
        filterSelectionError={filterSelectionError}
        filterObject={filterContent(handleTextChange, handleSelect, handleDate)}
        show={showFilter}
        onClose={closeFilter}
        handleReset={resetFilter}
        handleApply={handleApplyFilter}
        handleOnBlur={handleBlur}
      />
    </>
  );
};

export default StoreCoordinator;
